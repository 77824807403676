export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Void: any;
};

export type AgResult = {
  __typename?: 'AGResult';
  error?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type AccPhone = {
  __typename?: 'AccPhone';
  countryId?: Maybe<Scalars['String']>;
  number: Scalars['String'];
};

/** User's access level for an operations. */
export enum AccessLevel {
  /** Can edit, but some fields are restricted. */
  Contributor = 'Contributor',
  LimitedViewer = 'LimitedViewer',
  /** Can edit everything. */
  Manager = 'Manager',
  NoPermission = 'NoPermission',
  Viewer = 'Viewer'
}

export type AccessLevelsArguments = {
  __typename?: 'AccessLevelsArguments';
  targetDistrictId?: Maybe<Scalars['String']>;
  targetTermYear?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AccessLevelsOptions = {
  id?: Maybe<Scalars['String']>;
  targetDistrictId?: Maybe<Scalars['String']>;
  targetTermYearRange?: Maybe<Array<Maybe<Scalars['String']>>>;
  type: Scalars['String'];
};

/** Limited account data for new users. */
export type AccountInfoByToken = {
  __typename?: 'AccountInfoByToken';
  /** Okta id for the account. */
  accountId: Scalars['String'];
  /** The list of security questions for the user to choose from. */
  availableSecurityQuestions: Array<SecurityQuestion>;
  /** Current security question. */
  currentSecurityQuestion: Scalars['String'];
};

export type ActiveMembership = {
  __typename?: 'ActiveMembership';
  admissionDate: Scalars['String'];
  clubId: Scalars['String'];
  clubName?: Maybe<Scalars['String']>;
  clubType?: Maybe<Scalars['String']>;
  districtId: Scalars['String'];
  terminationDate?: Maybe<Scalars['String']>;
  type: MembershipType;
};

/** Input for the addClubMeeting mutation */
export type AddClubMeetingInput = {
  /** Club Id */
  clubId: Scalars['String'];
  /** Domino Id */
  dominoId?: Maybe<Scalars['String']>;
  /** Meeting */
  meeting: MeetingInput;
};

export type AddClubMeetingResult = {
  __typename?: 'AddClubMeetingResult';
  /** Created meeting */
  meeting?: Maybe<Meeting>;
  /** Created meeting id */
  meetingId?: Maybe<Scalars['String']>;
};

/** Input for the addClubVendors mutation */
export type AddClubVendorInput = {
  /** Access for */
  accessFor: Scalars['String'];
  /** Access Type */
  accessType: Scalars['String'];
  /** Certification */
  certification: Scalars['Boolean'];
  /** Club Key */
  clubKey: Scalars['String'];
  /** Vendor Key */
  vendorKey: Scalars['String'];
};

export type AddClubVendorResult = {
  __typename?: 'AddClubVendorResult';
  /** Created vendor id */
  vendorId?: Maybe<Scalars['String']>;
};

export type AddDelegationInput = {
  delegatedRole: Scalars['String'];
  delegateeEmail: Scalars['String'];
  delegateeMemberId: Scalars['String'];
  delegateeOrgTypeName: Scalars['String'];
  delegateePrefLangCode: Scalars['String'];
  endDate: Scalars['String'];
  memberId: Scalars['String'];
  roleKey: Scalars['String'];
  startDate: Scalars['String'];
};

export type AddMemberIndividualResult = {
  __typename?: 'AddMemberIndividualResult';
  individual: Individual;
  membershipList?: Maybe<Array<MembershipForAddMember>>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  /** State/Province/Territory for countries other than USA and Canada. */
  state?: Maybe<Scalars['String']>;
  /** State ID (as returned by the states field) for USA and Canada. */
  stateId?: Maybe<Scalars['String']>;
  type: AddressType;
};

export type AddressDetails = {
  __typename?: 'AddressDetails';
  AddressLine1?: Maybe<Scalars['String']>;
  AddressLine2?: Maybe<Scalars['String']>;
  AddressLine3?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  InternationalProvince?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
};

export type AddressInfo = {
  __typename?: 'AddressInfo';
  AddressLine1?: Maybe<Scalars['String']>;
  AddressLine2?: Maybe<Scalars['String']>;
  AddressLine3?: Maybe<Scalars['String']>;
  AddressType?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  InternationalProvince?: Maybe<Scalars['String']>;
  IsPrimary?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  MeetingComment?: Maybe<Scalars['String']>;
  MeetingDay?: Maybe<Scalars['String']>;
  MeetingLocationName?: Maybe<Scalars['String']>;
  MeetingName?: Maybe<Scalars['String']>;
  MeetingTime?: Maybe<Scalars['String']>;
  MeetingType?: Maybe<Scalars['String']>;
  MeetinglocationVariesFlag?: Maybe<Scalars['String']>;
  OnlineLocation?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
};

/** A physical location. */
export type AddressInput = {
  /** Name of the city. Latin characters only. Cannot start with a space, dot, comma or dash. Maximum length is 40. */
  city: Scalars['String'];
  /** Name of the country */
  country?: Maybe<Scalars['String']>;
  /** Id of the country as returned by the countries field. */
  countryId: Scalars['String'];
  /** Required if the country IS NOT one of USA, CAN - Must be empty if country IS one of above */
  internationalProvince?: Maybe<Scalars['String']>;
  /** Should not start with a space, dot, comma or dash. Must be Latin characters (Aa-Zz) and numbers, underscores, periods, and hyphens only. Length between 2 and 40. */
  lineOne: Scalars['String'];
  /** Must be empty if LineTwo is empty. Should not start with a space, dot, comma or dash. Must be Latin characters (Aa-Zz) and numbers, underscores, periods, and hyphens only. Maximum length is 40. */
  lineThree?: Maybe<Scalars['String']>;
  /** Should not start with a space, dot, comma or dash. Must be Latin characters (Aa-Zz) and numbers, underscores, periods, and hyphens only. Maximum length is 40. */
  lineTwo?: Maybe<Scalars['String']>;
  /** Required if the countryId IS one of USA, CAN, GUM, MSM, PRI, VIR, ASM, MHL, MNP, PLW. Otherwise, it's optional. Maximum length is 20. */
  postalCode?: Maybe<Scalars['String']>;
  /** State ID (as returned by the states field) for USA and Canada. */
  state?: Maybe<Scalars['String']>;
  /** Required if the country IS one of USA, CAN, and it must belongs to the country - Must be empty if country IS NOT one of above */
  stateId?: Maybe<Scalars['String']>;
  /** Address type. One of Business, Home, Localized, Project, HostCountry. Defaults to home. */
  type?: Maybe<AddressType>;
};

export enum AddressType {
  Business = 'Business',
  Home = 'Home',
  HostCountry = 'HostCountry',
  Localized = 'Localized',
  Project = 'Project'
}

export type Affiliation = LeadershipAffiliation | MembershipAffiliation;

export enum AffiliationType {
  Leadership = 'Leadership',
  Membership = 'Membership'
}

export type AgClubAffiliation = {
  __typename?: 'AgClubAffiliation';
  clubId: Scalars['String'];
  clubName: Scalars['String'];
  clubType: Scalars['String'];
  endDate: Scalars['String'];
  id: Scalars['String'];
  location?: Maybe<LocationData>;
  startDate: Scalars['String'];
};

/** Represents ApplicationModeResult. */
export type ApplicationModeResult = {
  __typename?: 'ApplicationModeResult';
  Config?: Maybe<Scalars['String']>;
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['String']>;
  ModeKey?: Maybe<Scalars['String']>;
  ModeName?: Maybe<Scalars['String']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
};

export type AssistantGovernor = {
  __typename?: 'AssistantGovernor';
  agId: Scalars['String'];
  clubsAffiliations?: Maybe<Array<Maybe<AgClubAffiliation>>>;
  districtLeaderships?: Maybe<Array<DistrictLeadership>>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  isDeceased?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  localizedName?: Maybe<Scalars['String']>;
  membershipClubs?: Maybe<Array<Maybe<DistrictMembershipClub>>>;
  middleName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photoUri?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  termInfo?: Maybe<AssistantGovernorTermInfo>;
};

export type AssistantGovernorByDate = {
  __typename?: 'AssistantGovernorByDate';
  agId: Scalars['String'];
  clubsAffiliations?: Maybe<Array<Maybe<AgClubAffiliation>>>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  localizedName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
};

export type AssistantGovernorTermInfo = {
  __typename?: 'AssistantGovernorTermInfo';
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type AssistantGovernorsByDateResults = {
  __typename?: 'AssistantGovernorsByDateResults';
  ags: Array<Maybe<AssistantGovernorByDate>>;
  totalCount: Scalars['Int'];
};

export type AssistantGovernorsResults = {
  __typename?: 'AssistantGovernorsResults';
  ags: Array<Maybe<AssistantGovernor>>;
  totalCount: Scalars['Int'];
};

export type BaCountry = {
  __typename?: 'BACountry';
  CountryISO2?: Maybe<Scalars['String']>;
  CountryISO3?: Maybe<Scalars['String']>;
  CountryKey?: Maybe<Scalars['String']>;
  CountryName?: Maybe<Scalars['String']>;
  CountryTranslations?: Maybe<Array<Maybe<CountryTranslations>>>;
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  AlternateBankAccName?: Maybe<Scalars['String']>;
  BankAccountKey?: Maybe<Scalars['String']>;
  BankCode?: Maybe<Scalars['String']>;
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
};

/** Represents a Bank Account Assignment Information. */
export type BankAssignmentResult = {
  __typename?: 'BankAssignmentResult';
  BankAccount?: Maybe<BankAccount>;
  BankAccountAssignmentKey?: Maybe<Scalars['String']>;
  CountryCurrency?: Maybe<CountryCurrency>;
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  LegalEntity?: Maybe<LegalEntity>;
  ModifiedByUser?: Maybe<Scalars['String']>;
  Notes?: Maybe<Scalars['String']>;
  TaxID?: Maybe<Scalars['String']>;
};

/** Represents where a club is located. It is like an Address but does not require an AddressType */
export type BaseAddress = {
  __typename?: 'BaseAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  /** Domino address id field */
  id?: Maybe<Scalars['String']>;
  /** State/Province/Territory for countries other than USA and Canada. */
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  /** State ID (as returned by the states field) for USA and Canada. */
  stateId?: Maybe<Scalars['String']>;
};

/** Same as AddressInput, but without the type. */
export type BaseAddressInput = {
  action?: Maybe<Scalars['String']>;
  /** Name of the city. Latin characters only. Cannot start with a space, dot, comma or dash. Maximum length is 40. */
  city: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  /** Id of the country as returned by the countries field. */
  countryId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  /** Required if the country IS NOT one of USA, CAN - Must be empty if country IS one of above */
  internationalProvince?: Maybe<Scalars['String']>;
  /** Should not start with a space, dot, comma or dash. Must be Latin characters (Aa-Zz) and numbers, underscores, periods, and hyphens only. Length between 2 and 40. */
  lineOne: Scalars['String'];
  /** Must be empty if LineTwo is empty. Should not start with a space, dot, comma or dash. Must be Latin characters (Aa-Zz) and numbers, underscores, periods, and hyphens only. Maximum length is 40. */
  lineThree?: Maybe<Scalars['String']>;
  /** Should not start with a space, dot, comma or dash. Must be Latin characters (Aa-Zz) and numbers, underscores, periods, and hyphens only. Maximum length is 40. */
  lineTwo?: Maybe<Scalars['String']>;
  /** Required if the countryId IS one of USA, CAN, GUM, MSM, PRI, VIR, ASM, MHL, MNP, PLW. Otherwise, it's optional. Maximum length is 20. */
  postalCode?: Maybe<Scalars['String']>;
  /** State ID (as returned by the states field) for USA and Canada. */
  state?: Maybe<Scalars['String']>;
  /** Required if the country IS one of USA, CAN, and it must belongs to the country - Must be empty if country IS NOT one of above */
  stateId?: Maybe<Scalars['String']>;
};

export type BasicInfo = {
  __typename?: 'BasicInfo';
  crmIndividualInfo?: Maybe<CrmIndividualInfoType>;
  groupApplicationRolesRelationship?: Maybe<Array<Maybe<GroupApplicationRolesRelationshipType>>>;
  oktaProfileData?: Maybe<OktaProfileDataType>;
  rolesAuthenticationTicketData?: Maybe<Array<Maybe<RolesAuthenticationTicketDataType>>>;
};

/** Basic Language input */
export type BasicLanguageInput = {
  action?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  languageId: Scalars['String'];
};

export type CrmIndividualInfoType = {
  __typename?: 'CRMIndividualInfoType';
  individualKey?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  preferredLanguage?: Maybe<Scalars['String']>;
  userSortName?: Maybe<Scalars['String']>;
};

export type CalendarEvent = {
  /** What kind of event this is. */
  categories?: Maybe<Array<Scalars['String']>>;
  /** Human-readable comments about this event. */
  comment?: Maybe<Array<Scalars['String']>>;
  /** Human-readable description of this event. */
  description?: Maybe<Scalars['String']>;
  /** Date and time at which this event ends. (ISO 8601 date or date/time) */
  dtend?: Maybe<Scalars['String']>;
  /** Modification timestamp of this event. (Seconds since Unix epoch) */
  dtstamp: Scalars['Int'];
  /** Date and time at which this event starts. (ISO 8601 date or date/time) */
  dtstart?: Maybe<Scalars['String']>;
  /** How long this event lasts. (Seconds) */
  duration?: Maybe<Scalars['Int']>;
  /** Geographic coordinates of this event. */
  geo?: Maybe<GeoLocation>;
  /** Physical address of this event. */
  location?: Maybe<Scalars['String']>;
  /** How the event recurs. */
  recurrence?: Maybe<Recurrence>;
  /** Unique identifier for this event. */
  uid: Scalars['String'];
  /** Internet address of this event. */
  url?: Maybe<Scalars['String']>;
};

/** Represents Cancel subscription status. */
export type CancelSubscriptionResponse = {
  __typename?: 'CancelSubscriptionResponse';
  Message?: Maybe<Scalars['String']>;
};

export type CategoriesByLanguage = {
  __typename?: 'CategoriesByLanguage';
  de?: Maybe<Array<Maybe<Category>>>;
  en?: Maybe<Array<Maybe<Category>>>;
  es?: Maybe<Array<Maybe<Category>>>;
  fr?: Maybe<Array<Maybe<Category>>>;
  it?: Maybe<Array<Maybe<Category>>>;
  ja?: Maybe<Array<Maybe<Category>>>;
  ko?: Maybe<Array<Maybe<Category>>>;
  pt?: Maybe<Array<Maybe<Category>>>;
};

/** Represents categories. */
export type CategoriesResult = {
  __typename?: 'CategoriesResult';
  /** categories. */
  categories?: Maybe<CategoriesByLanguage>;
};

export type Category = {
  __typename?: 'Category';
  blurb?: Maybe<Scalars['String']>;
  content?: Maybe<Array<Maybe<Content>>>;
  id?: Maybe<Scalars['String']>;
  roleAccess?: Maybe<RoleAccess>;
  title?: Maybe<Scalars['String']>;
};

/** Club object, as returned for get club query */
export type Club = {
  __typename?: 'Club';
  charteredDate?: Maybe<Scalars['String']>;
  childClubRelationships?: Maybe<Array<ClubRelationship>>;
  clubId: Scalars['String'];
  clubName: Scalars['String'];
  clubType: Scalars['String'];
  district?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  email?: Maybe<DominoEmailType>;
  /** Club leadership roles */
  leaders?: Maybe<Array<ClubLeadershipItem>>;
  mailingAddress?: Maybe<BaseAddress>;
  meetings: Array<Meeting>;
  members: MemberSearchResults;
  officialLanguage?: Maybe<Language>;
  operationsAccess: ClubOperationsAccess;
  organizationBase?: Maybe<OrganizationBase>;
  parentClubRelationships?: Maybe<Array<ClubRelationship>>;
  physicalLocation?: Maybe<BaseAddress>;
  primaryEmail?: Maybe<Scalars['String']>;
  primaryFax?: Maybe<Fax>;
  primaryPhone?: Maybe<PhoneNumber>;
  primaryWebsite?: Maybe<Scalars['String']>;
  riClubId: Scalars['String'];
  status: ClubStatus;
  vendors: Array<ClubVendor>;
  website?: Maybe<WebsiteType>;
};


/** Club object, as returned for get club query */
export type ClubLeadersArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  termStartYear?: Maybe<Scalars['Int']>;
};


/** Club object, as returned for get club query */
export type ClubMembersArgs = {
  email?: Maybe<Scalars['String']>;
  isMultifilterAllowed?: Maybe<Scalars['Boolean']>;
  membershipStatus?: Maybe<MembershipStatusFilter>;
  membershipStatuses?: Maybe<Array<Maybe<MembershipStatusFilter>>>;
  name?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  riClubId?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ClubAffiliation = {
  __typename?: 'ClubAffiliation';
  affiliations: Array<Affiliation>;
  clubFullName: Scalars['String'];
  clubId: Scalars['String'];
  clubLocation?: Maybe<BaseAddress>;
  clubName: Scalars['String'];
  clubType: ClubType;
  district?: Maybe<DistrictType>;
  meetings?: Maybe<Array<Maybe<Meeting>>>;
};

export type ClubAffiliationFilters = {
  affiliationTypes?: Maybe<Array<AffiliationType>>;
  clubId?: Maybe<Scalars['String']>;
  membershipTypes?: Maybe<Array<MembershipType>>;
};

export type ClubAssignmentsResponse = {
  __typename?: 'ClubAssignmentsResponse';
  errorBatch?: Maybe<Scalars['String']>;
  finalResult?: Maybe<ClubAssignmentsResult>;
};

export type ClubAssignmentsResult = {
  __typename?: 'ClubAssignmentsResult';
  errorResults: Array<ErrorAssignmentsResult>;
  successResults: Array<SuccessAssignmentsResult>;
};

export type ClubCredits = {
  __typename?: 'ClubCredits';
  annualFund?: Maybe<Scalars['Float']>;
  asOfDate: Scalars['String'];
  polioPlus?: Maybe<Scalars['Float']>;
  prevYearDeltas: ClubCreditsDeltas;
  rotaryYear: Scalars['Int'];
};

export type ClubCreditsDeltas = {
  __typename?: 'ClubCreditsDeltas';
  annualFund?: Maybe<Scalars['Float']>;
  polioPlus?: Maybe<Scalars['Float']>;
  prevYearAsOfDate: Scalars['String'];
};

export type ClubDetails = {
  __typename?: 'ClubDetails';
  ClubCountry?: Maybe<Scalars['String']>;
  /** Member Club Details */
  ClubId?: Maybe<Scalars['Int']>;
  ClubName?: Maybe<Scalars['String']>;
  MemberType?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
};

export type ClubDetailsResult = {
  __typename?: 'ClubDetailsResult';
  Address?: Maybe<Scalars['String']>;
  CharterDate?: Maybe<Scalars['String']>;
  ClubId?: Maybe<Scalars['Int']>;
  ClubLanguage?: Maybe<Scalars['String']>;
  ClubName?: Maybe<Scalars['String']>;
  ClubSubType?: Maybe<Scalars['String']>;
  ClubType?: Maybe<Scalars['String']>;
  District?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  FaxCountryCode?: Maybe<Scalars['String']>;
  FaxCountryName?: Maybe<Scalars['String']>;
  FaxExtension?: Maybe<Scalars['String']>;
  FaxKey?: Maybe<Scalars['String']>;
  FaxLastUpdated?: Maybe<Scalars['String']>;
  FaxNumber?: Maybe<Scalars['String']>;
  FaxType?: Maybe<Scalars['String']>;
  IsPrimaryFax?: Maybe<Scalars['Boolean']>;
  IsPrimaryPhone?: Maybe<Scalars['Boolean']>;
  Latitude?: Maybe<Scalars['Int']>;
  Location?: Maybe<ClubLocation>;
  Longitude?: Maybe<Scalars['Int']>;
  NumberOfActiveMembers?: Maybe<Scalars['Int']>;
  PhoneCountryCode?: Maybe<Scalars['String']>;
  PhoneCountryName?: Maybe<Scalars['String']>;
  PhoneExtension?: Maybe<Scalars['String']>;
  PhoneKey?: Maybe<Scalars['String']>;
  PhoneLastUpdated?: Maybe<Scalars['String']>;
  PhoneNumber?: Maybe<Scalars['String']>;
  PhoneType?: Maybe<Scalars['String']>;
  Website?: Maybe<Scalars['String']>;
};

export type ClubDevelopment = {
  __typename?: 'ClubDevelopment';
  july1st: Scalars['Int'];
  newClubs: Scalars['Int'];
  newMembers: Scalars['Int'];
  terminatedClubs: Scalars['Int'];
  terminatedMembers: Scalars['Int'];
  totalCount: Scalars['Int'];
  year: Scalars['Int'];
};

export type ClubInvoiceResult = {
  __typename?: 'ClubInvoiceResult';
  fileContent?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ClubLeadership = {
  __typename?: 'ClubLeadership';
  clubId?: Maybe<Scalars['String']>;
  clubName?: Maybe<Scalars['String']>;
  clubType?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  riClubId?: Maybe<Scalars['String']>;
  riDistrictId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

/** Represents a leadership position in club. */
export type ClubLeadershipItem = {
  __typename?: 'ClubLeadershipItem';
  /** Optional end date of this leadership. */
  endDate?: Maybe<Scalars['String']>;
  /** The leadership item id. */
  id?: Maybe<Scalars['String']>;
  /** An individual's id. */
  individualId?: Maybe<Scalars['String']>;
  /** The individual's name. */
  individualName?: Maybe<Scalars['String']>;
  /** A flag telling if it's a delegated position. */
  isDelegated?: Maybe<Scalars['Boolean']>;
  /** The photo URI. */
  photoUri?: Maybe<Scalars['String']>;
  /** Leader's primary email */
  primaryEmail?: Maybe<Scalars['String']>;
  /** The leadership role. */
  role: Role;
  /** Start date of this leadership. */
  startDate: Scalars['String'];
};

/** Represents club details by ClubID and ClubType. */
export type ClubLocation = {
  __typename?: 'ClubLocation';
  ClubCountry?: Maybe<Scalars['String']>;
  ClubInternationalProvince?: Maybe<Scalars['String']>;
  ClubState?: Maybe<Scalars['String']>;
};

/** Represents Member Details based on parameters : MemberId. */
export type ClubMemberDetails = {
  __typename?: 'ClubMemberDetails';
  AddressCountry?: Maybe<Scalars['String']>;
  AddressKey?: Maybe<Scalars['String']>;
  AddressLine1?: Maybe<Scalars['String']>;
  AddressLine2?: Maybe<Scalars['String']>;
  AddressLine3?: Maybe<Scalars['String']>;
  AddressType?: Maybe<Scalars['String']>;
  AdmissionDate?: Maybe<Scalars['String']>;
  BadgeName?: Maybe<Scalars['String']>;
  BirthYear?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Classification?: Maybe<Scalars['String']>;
  DateOfBirth?: Maybe<Scalars['String']>;
  EmailAddress?: Maybe<Scalars['String']>;
  EmailKey?: Maybe<Scalars['String']>;
  EmailType?: Maybe<Scalars['String']>;
  FaxCountryCode?: Maybe<Scalars['String']>;
  FaxCountryName?: Maybe<Scalars['String']>;
  FaxExtension?: Maybe<Scalars['String']>;
  FaxKey?: Maybe<Scalars['String']>;
  FaxNumber?: Maybe<Scalars['String']>;
  FaxType?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  /** Member Details */
  Gender?: Maybe<Scalars['String']>;
  InternationalProvince?: Maybe<Scalars['String']>;
  IsOnlineEmail?: Maybe<Scalars['Boolean']>;
  IsPrimaryAddress?: Maybe<Scalars['String']>;
  IsPrimaryEmail?: Maybe<Scalars['Boolean']>;
  IsSatelliteMember?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  LastName?: Maybe<Scalars['String']>;
  LocalizedName?: Maybe<Scalars['String']>;
  MemberId?: Maybe<Scalars['Int']>;
  MemberType?: Maybe<Scalars['String']>;
  MiddleName?: Maybe<Scalars['String']>;
  OriginalAdmissionDate?: Maybe<Scalars['String']>;
  PhoneCountryCode?: Maybe<Scalars['String']>;
  PhoneCountryName?: Maybe<Scalars['String']>;
  PhoneExtension?: Maybe<Scalars['String']>;
  PhoneKey?: Maybe<Scalars['String']>;
  PhoneNumber?: Maybe<Scalars['String']>;
  PhoneType?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  Prefix?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Suffix?: Maybe<Scalars['String']>;
  TerminationDate?: Maybe<Scalars['String']>;
  TerminationReason?: Maybe<Scalars['String']>;
  WebsiteAddress?: Maybe<Scalars['String']>;
  WebsiteKey?: Maybe<Scalars['String']>;
  WebsiteType?: Maybe<Scalars['String']>;
};

/** Represents Club Member Details based on parameters : Club Id and Club type. */
export type ClubMembersResult = {
  __typename?: 'ClubMembersResult';
  /** Member Details */
  ClubMembers?: Maybe<Array<Maybe<ClubMemberDetails>>>;
  ClubName: Scalars['String'];
};

export type ClubMembership = {
  __typename?: 'ClubMembership';
  admissionDate?: Maybe<Scalars['String']>;
  clubId?: Maybe<Scalars['String']>;
  clubName?: Maybe<Scalars['String']>;
  clubType?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  riClubId?: Maybe<Scalars['String']>;
  riDistrictId?: Maybe<Scalars['String']>;
  terminationDate?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ClubOperationsAccess = {
  __typename?: 'ClubOperationsAccess';
  club: AccessLevel;
  /** That's a quick workaround used on backend. */
  clubId: Scalars['String'];
  financesGoalsReports: AccessLevel;
  leaderships: AccessLevel;
  memberships: AccessLevel;
};

export type ClubRelationship = {
  __typename?: 'ClubRelationship';
  childClubId?: Maybe<Scalars['String']>;
  childDisId?: Maybe<Scalars['String']>;
  clubName?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  parentClubId?: Maybe<Scalars['String']>;
  parentDisId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  type?: Maybe<ClubType>;
};

export type ClubSearchConstants = {
  __typename?: 'ClubSearchConstants';
  /** Permitted filter values. */
  filter: ClubSearchFilterConstants;
};

export type ClubSearchFilterConstants = {
  __typename?: 'ClubSearchFilterConstants';
  /** Filter values for meetingType. */
  meetingType: Array<Scalars['String']>;
};

/** Club search result object, as they are returned for search query */
export type ClubSearchResult = {
  __typename?: 'ClubSearchResult';
  /** Well-known ID of the club's district. */
  districtId?: Maybe<Scalars['Int']>;
  /** Full name of this club. */
  fullName: Scalars['String'];
  /** UUID of this club. */
  id: Scalars['String'];
  /** Mailing address of this club; not necessarily a meeting site. */
  mailingAddress?: Maybe<Address>;
  /** When, where, and how this club meets. */
  meetings: Array<ClubSearchResultMeeting>;
  /** Terse name of this club. */
  name: Scalars['String'];
  /** URLs of other websites maintained by the club. */
  otherWebsites?: Maybe<Array<Scalars['String']>>;
  /** Physical Location of searched club. */
  physicalLocation?: Maybe<ClubSearchResutLocation>;
  /** URL of the primary website maintained by the club. */
  primaryWebsite?: Maybe<Scalars['String']>;
  /** Well-known ID of this club. */
  riClubId: Scalars['String'];
  /** Clubs that sponsor this club. */
  sponsorClubs?: Maybe<Array<ClubSearchResultClubRelationship>>;
  /** Clubs that this club sponsors. */
  sponsoredClubs?: Maybe<Array<ClubSearchResultClubRelationship>>;
  /** Status of this club, e.g. Active or Terminated. */
  status: Scalars['String'];
  /** Type of this club, e.g. Rotary_Club, Rotaract_Club. */
  type: ClubType;
};

/** Time-bounded pointer to another club. */
export type ClubSearchResultClubRelationship = {
  __typename?: 'ClubSearchResultClubRelationship';
  /** Terse name of the other club. */
  clubName: Scalars['String'];
  /** When this relationship ended. (ISO 8601 date/time) */
  endDate?: Maybe<Scalars['String']>;
  /** UUID of the other club. */
  id: Scalars['String'];
  /** When this relationship began. (ISO 8601 date/time) */
  startDate: Scalars['String'];
  /** Type of the other club. */
  type: Scalars['String'];
};

/** Club search result value object for a club meeting */
export type ClubSearchResultMeeting = CalendarEvent & {
  __typename?: 'ClubSearchResultMeeting';
  /** What kind of event this is. */
  categories?: Maybe<Array<Scalars['String']>>;
  /** Human-readable comments about this event. */
  comment?: Maybe<Array<Scalars['String']>>;
  /** Human-readable description of this event. */
  description?: Maybe<Scalars['String']>;
  /** Date and time at which this event ends. (ISO 8601 date or date/time) */
  dtend?: Maybe<Scalars['String']>;
  /** Modification timestamp of this event. (Seconds since Unix epoch) */
  dtstamp: Scalars['Int'];
  /** Date and time at which this event starts. (ISO 8601 date or date/time) */
  dtstart?: Maybe<Scalars['String']>;
  /** How long this event lasts. (Seconds) */
  duration?: Maybe<Scalars['Int']>;
  /** Geographic coordinates of this event. */
  geo?: Maybe<GeoLocation>;
  /** Physical address of this event. */
  location?: Maybe<Scalars['String']>;
  /** How the event recurs. */
  recurrence?: Maybe<Recurrence>;
  /** Unique identifier for this event. */
  uid: Scalars['String'];
  /** Internet address of this event. */
  url?: Maybe<Scalars['String']>;
  /** Physical address of the meeting location. */
  xRiAddress?: Maybe<BaseAddress>;
  /** 3-letter ISO code for the meeting's language. */
  xRiLanguageId?: Maybe<Scalars['String']>;
  /** English name for the meeting's language, e.g. German. */
  xRiLanguageName?: Maybe<Scalars['String']>;
  /** Whether the location of this event varies. */
  xRiLocationVaries: Scalars['Boolean'];
  /** Meeting's time of day on a 24-hour clock, e.g. 06:25 or 17:30. */
  xRiTime?: Maybe<Scalars['String']>;
  /** Meeting's day of the week, in English. */
  xRiWeekday?: Maybe<Scalars['String']>;
};

/** Club search result object, as they are returned for search query */
export type ClubSearchResults = {
  __typename?: 'ClubSearchResults';
  /** Object containing all results returned from the search.  */
  results: Array<ClubSearchResult>;
  /** Total number of results.  */
  totalCount: Scalars['Int'];
};

export type ClubSearchResutLocation = {
  __typename?: 'ClubSearchResutLocation';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
};

export enum ClubStatus {
  Active = 'Active',
  Suspended = 'Suspended',
  Terminated = 'Terminated'
}

export type ClubStatusResponse = {
  __typename?: 'ClubStatusResponse';
  status: Array<Maybe<ClubStatus>>;
};

export enum ClubType {
  RotaractClub = 'RotaractClub',
  RotaractSatelliteClub = 'RotaractSatelliteClub',
  RotaryClub = 'RotaryClub',
  SatelliteClub = 'SatelliteClub'
}

export enum ClubTypeFilterValue {
  RotaractClub = 'Rotaract_Club',
  RotaractSatelliteClub = 'Rotaract_Satellite_Club',
  RotaryClub = 'Rotary_Club',
  SatelliteClub = 'Satellite_Club'
}

export type ClubVendor = {
  __typename?: 'ClubVendor';
  clubId?: Maybe<Scalars['String']>;
  clubType?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  isPreferred?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  organizationType?: Maybe<Scalars['String']>;
  requestors: Array<VendorRequestor>;
  startDate?: Maybe<Scalars['String']>;
  vendorKey: Scalars['String'];
  vendorName?: Maybe<Scalars['String']>;
};

export type CommitteeRolesResult = {
  __typename?: 'CommitteeRolesResult';
  endDate: Scalars['String'];
  id: Scalars['String'];
  startDate: Scalars['String'];
  type: Scalars['String'];
};

export type ConferenceAddress = {
  __typename?: 'ConferenceAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type ConferenceFeedbackResult = {
  __typename?: 'ConferenceFeedbackResult';
  id: Scalars['String'];
  type: Scalars['String'];
};

export type Constants = {
  __typename?: 'Constants';
  clubSearch: ClubSearchConstants;
  /** The English name of each day of the week. */
  weekdayNames: Array<Scalars['String']>;
};

export type ContactFormInput = {
  club?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  country: Scalars['String'];
  district?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  primaryPhone?: Maybe<PrimaryPhoneInput>;
  subject: Scalars['String'];
};

export type Content = {
  __typename?: 'Content';
  component?: Maybe<Scalars['String']>;
  config?: Maybe<ContentConfig>;
  defaultFund?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  mode?: Maybe<Scalars['String']>;
};

export type ContentConfig = {
  __typename?: 'ContentConfig';
  funds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type Contribution = {
  __typename?: 'Contribution';
  annualFund: Array<Maybe<ContributionItem>>;
  clubCredits: Array<Maybe<ClubCredits>>;
  polioPlus: Array<Maybe<ContributionItem>>;
};

export type ContributionDeltas = {
  __typename?: 'ContributionDeltas';
  prevYearAsOfDate: Scalars['String'];
  priorMonthEnd: Scalars['Float'];
  totalFor30June?: Maybe<Scalars['Float']>;
};

export type ContributionItem = {
  __typename?: 'ContributionItem';
  asOfDate: Scalars['String'];
  prevYearDeltas: ContributionDeltas;
  priorMonthEnd?: Maybe<Scalars['Float']>;
  rotaryYear: Scalars['Int'];
  totalFor30June?: Maybe<Scalars['Float']>;
};

/** Represents a country. */
export type Country = {
  __typename?: 'Country';
  /** The country's id. This one should be sent to DIS when writting data. */
  id: Scalars['String'];
  /** The name of the country. */
  name: Scalars['String'];
  /** Two letter code of the country. This one should NOT be used when writing data to DIS. */
  shortCode: Scalars['String'];
};

export type CountryCurrency = {
  __typename?: 'CountryCurrency';
  Country?: Maybe<BaCountry>;
  CountryCurrencyKey?: Maybe<Scalars['String']>;
  CountryKey?: Maybe<Scalars['String']>;
  CreatedByUser?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyKey?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  IsPrimary?: Maybe<Scalars['Boolean']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
};

/** Represents country currency mapping. */
export type CountryCurrencyMapped = {
  __typename?: 'CountryCurrencyMapped';
  default?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  list: Array<CurrencyId>;
};

/** Represents country. */
export type CountryDetails = {
  __typename?: 'CountryDetails';
  /** country */
  id?: Maybe<Scalars['String']>;
  iso2?: Maybe<Scalars['String']>;
  name?: Maybe<CountryTNames>;
};

export type CountryTNames = {
  __typename?: 'CountryTNames';
  de?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  ja?: Maybe<Scalars['String']>;
  ko?: Maybe<Scalars['String']>;
  pt?: Maybe<Scalars['String']>;
  sv?: Maybe<Scalars['String']>;
};

export type CountryTranslations = {
  __typename?: 'CountryTranslations';
  CountryKey?: Maybe<Scalars['String']>;
  CountryTranslateKey?: Maybe<Scalars['String']>;
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  LanguageCode?: Maybe<Scalars['String']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
  TranslatedName?: Maybe<Scalars['String']>;
};

export type CreateIndividualInput = {
  dateOfBirth?: Maybe<Scalars['String']>;
  /** First name (Given name). Max length is 30 chars. Cannot start with a space, dash or dot. Mostly latin characters, DIS regex: `/^[\\ -\\ɏ]*$/`. */
  firstName: Scalars['String'];
  /**
   * Gender. One of Male, Female, Do Not Identify, Not Given, Self Identify. If
   * not provided, set to Not Given.
   */
  gender: Gender;
  /** Gender description. Should be provided when Gender is Self Identify. */
  genderDescription?: Maybe<Scalars['String']>;
  /** Last name (Family name). Max length is 30 chars. Cannot start with a space, dash or dot. Mostly latin characters, DIS regex: `/^[\\ -\\ɏ]*$/`. */
  lastName: Scalars['String'];
  /** Localized name (Full name). Max length is 200 chars. Supports uncode. */
  localizedName?: Maybe<Scalars['String']>;
  /** Middle name. Max length is 30 chars. Cannot start with a space, dash or dot. Mostly latin characters, DIS regex: `/^[\\ -\\ɏ]*$/`. */
  middleName?: Maybe<Scalars['String']>;
  otherAddresses?: Maybe<Array<AddressInput>>;
  otherEmails?: Maybe<Array<EmailInput>>;
  otherExpertise?: Maybe<Array<ExpertiseInput>>;
  otherLanguages?: Maybe<Array<Scalars['String']>>;
  otherPhones?: Maybe<Array<IndividualPhoneInput>>;
  otherProfessions?: Maybe<Array<ProfessionInput>>;
  prefix?: Maybe<Scalars['String']>;
  /** A physical location. */
  primaryAddress?: Maybe<AddressInput>;
  /** Email address. */
  primaryEmail?: Maybe<EmailInput>;
  /** Areas of expertise. */
  primaryExpertise?: Maybe<ExpertiseInput>;
  /** Id of the primary language as returned by the languages field. */
  primaryLanguage: Scalars['String'];
  /** A phone number. */
  primaryPhone?: Maybe<IndividualPhoneInput>;
  /** Professional experience. */
  primaryProfession?: Maybe<ProfessionInput>;
  /** Allow share sponsorship or not */
  recognitionOptOutFlag?: Maybe<Scalars['Boolean']>;
  suffix?: Maybe<Scalars['String']>;
  /** Format YYYY. Must be between this year and 100 years ago. Should be set if youthFlag is true. */
  yearOfBirth?: Maybe<Scalars['Int']>;
  /** Whether the individual is under 18 years old. */
  youthFlag?: Maybe<Scalars['Boolean']>;
};

export type CreateIndividualProgramInput = {
  fields?: Maybe<Array<ProgramFieldInput>>;
  individualId: Scalars['String'];
  programId: Scalars['String'];
};

/** Result of the createOrUpdateIndividual mutation. */
export type CreateOrUpdateIndividualResult = {
  __typename?: 'CreateOrUpdateIndividualResult';
  individualId: Scalars['String'];
};

export type CreditOrg = {
  __typename?: 'CreditOrg';
  Id?: Maybe<Scalars['String']>;
  Key?: Maybe<Scalars['String']>;
  LegacyTypeId?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type CreditOrgDetails = {
  __typename?: 'CreditOrgDetails';
  District?: Maybe<Scalars['String']>;
  Id?: Maybe<Scalars['Int']>;
  Key?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type Currency = {
  __typename?: 'Currency';
  CreatedByUser?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyKey?: Maybe<Scalars['String']>;
  CurrencyName?: Maybe<Scalars['String']>;
  CurrencyTranslations?: Maybe<Array<Maybe<CurrencyTranslations>>>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
};

/** Represents currency. */
export type CurrencyDetails = {
  __typename?: 'CurrencyDetails';
  code?: Maybe<Scalars['String']>;
  /** currency */
  id?: Maybe<Scalars['String']>;
  name?: Maybe<CurrencyTNames>;
};

export type CurrencyId = {
  __typename?: 'CurrencyId';
  currencyId: Scalars['String'];
};

export type CurrencyTNames = {
  __typename?: 'CurrencyTNames';
  de?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  ja?: Maybe<Scalars['String']>;
  ko?: Maybe<Scalars['String']>;
  pt?: Maybe<Scalars['String']>;
  sv?: Maybe<Scalars['String']>;
  und?: Maybe<Scalars['String']>;
};

export type CurrencyTranslations = {
  __typename?: 'CurrencyTranslations';
  CreatedByUser?: Maybe<Scalars['String']>;
  CurrencyKey?: Maybe<Scalars['String']>;
  CurrencyTranslateKey?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  LanguageCode?: Maybe<Scalars['String']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
  TranslatedName?: Maybe<Scalars['String']>;
};

export type DesDistrictInfo = {
  __typename?: 'DESDistrictInfo';
  districtId: Scalars['String'];
  riDistrictId: Scalars['String'];
  zone?: Maybe<Scalars['String']>;
};

export type DgResult = {
  __typename?: 'DGResult';
  fullName: Scalars['String'];
  riDistrictId: Scalars['String'];
  roleName: Scalars['String'];
};

export enum DashboardClubType {
  RotaractClub = 'RotaractClub',
  RotaryClub = 'RotaryClub'
}

export type DaySpecifier = {
  __typename?: 'DaySpecifier';
  /** Ordinal number of this day within its month or year. */
  offset?: Maybe<Scalars['Int']>;
  /** Day of the week. */
  weekday: WeekDay;
};

export type Delegates = {
  __typename?: 'Delegates';
  DelegationEndDate?: Maybe<Scalars['String']>;
  DelegationStartDate?: Maybe<Scalars['String']>;
  DelegatorFullName?: Maybe<Scalars['String']>;
  DelegatorMemberId?: Maybe<Scalars['String']>;
  Key?: Maybe<Scalars['String']>;
  MemberId?: Maybe<Scalars['String']>;
  OrganizationId?: Maybe<Scalars['String']>;
  OrganizationName?: Maybe<Scalars['String']>;
  OrganizationType?: Maybe<Scalars['String']>;
  Role?: Maybe<Scalars['String']>;
  RoleEndDate?: Maybe<Scalars['String']>;
  RoleStartDate?: Maybe<Scalars['String']>;
};

export type DelegationRoles = {
  __typename?: 'DelegationRoles';
  Committee?: Maybe<Scalars['String']>;
  CommitteeType?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  IsDelegationEligible: Scalars['Boolean'];
  Key: Scalars['String'];
  LastName?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  Location?: Maybe<Location>;
  MemberId?: Maybe<Scalars['String']>;
  MiddleName?: Maybe<Scalars['String']>;
  Organization?: Maybe<Scalars['String']>;
  OrganizationId?: Maybe<Scalars['String']>;
  OrganizationType?: Maybe<Scalars['String']>;
  Role: Scalars['String'];
  RoleKey: Scalars['String'];
  StartDate: Scalars['String'];
  TerminationReason?: Maybe<Scalars['String']>;
};

export type Delegations = {
  __typename?: 'Delegations';
  DelegateeFullName?: Maybe<Scalars['String']>;
  DelegateeMemberId?: Maybe<Scalars['String']>;
  DelegationEndDate?: Maybe<Scalars['String']>;
  DelegationStartDate?: Maybe<Scalars['String']>;
  Key?: Maybe<Scalars['String']>;
  MemberId?: Maybe<Scalars['String']>;
  OrganizationId?: Maybe<Scalars['String']>;
  OrganizationName?: Maybe<Scalars['String']>;
  OrganizationType?: Maybe<Scalars['String']>;
  Role?: Maybe<Scalars['String']>;
  RoleEndDate?: Maybe<Scalars['String']>;
  RoleStartDate?: Maybe<Scalars['String']>;
};

/** Input for the deleteClubMeeting mutation */
export type DeleteClubMeetingInput = {
  /** Club Id */
  clubId: Scalars['String'];
  /** Domino Id */
  dominoId?: Maybe<Scalars['String']>;
  /** MeetingId */
  meetingId: Scalars['String'];
};

export type DeleteClubMeetingResult = {
  __typename?: 'DeleteClubMeetingResult';
  isMeetingDeleted?: Maybe<Scalars['Boolean']>;
};

export type DeleteDelegationInput = {
  delegationKey: Scalars['String'];
  memberId: Scalars['String'];
};

export type DeleteIndividualProgramInput = {
  individualId: Scalars['String'];
  individualProgramId: Scalars['String'];
};

export type Demographic = {
  __typename?: 'Demographic';
  otherGendersCount: Scalars['Int'];
  over40: Scalars['Int'];
  period: Scalars['String'];
  under40: Scalars['Int'];
  unreported: Scalars['Int'];
  womenCount: Scalars['Int'];
};

/** Represents a displayname. */
export type DisplayNames = {
  __typename?: 'DisplayNames';
  id: Scalars['String'];
  name: Scalars['String'];
  /** Two letter code of the displayname. */
  shortCode: Scalars['String'];
};

/** District object, as they are returned for query. */
export type District = {
  __typename?: 'District';
  /** The district name */
  DistrictName: Scalars['String'];
  /** The district number */
  DistrictNumber: Scalars['Int'];
};

export type DistrictAddress = {
  __typename?: 'DistrictAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
};

export type DistrictAddressInput = {
  action?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  /** State/Province/Territory for countries other than USA and Canada. */
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  /** State ID (as returned by the states field) for USA and Canada. */
  stateId?: Maybe<Scalars['String']>;
};

export type DistrictClub = {
  __typename?: 'DistrictClub';
  activeMembersCount?: Maybe<Scalars['Int']>;
  district?: Maybe<DistrictData>;
  governor?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  location?: Maybe<LocationData>;
  meetings?: Maybe<Array<Maybe<Meeting>>>;
  name: Scalars['String'];
  riClubId: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  type: ClubType;
};

export type DistrictClubsResults = {
  __typename?: 'DistrictClubsResults';
  /** Object containing all results returned from the search.  */
  clubs: Array<DistrictClub>;
  /** Total number of results.  */
  totalCount: Scalars['Int'];
};

export type DistrictClubsStatistics = {
  __typename?: 'DistrictClubsStatistics';
  clubType: Scalars['String'];
  totalCount: Scalars['Int'];
};

export type DistrictDis3 = {
  __typename?: 'DistrictDIS3';
  businessAddress?: Maybe<Array<Maybe<DistrictAddress>>>;
  districtLegalName?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<DistrictEmails>>>;
  id: Scalars['String'];
  localizedAddress?: Maybe<DistrictAddress>;
  mailingAddress?: Maybe<DistrictAddress>;
  otherEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  otherFaxes?: Maybe<Array<Maybe<DistrictFax>>>;
  otherPhones?: Maybe<Array<Maybe<DistrictPhone>>>;
  otherWebsites?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryEmail?: Maybe<Scalars['String']>;
  primaryFax?: Maybe<DistrictFax>;
  primaryPhone?: Maybe<DistrictPhone>;
  primaryWebsite?: Maybe<Scalars['String']>;
  riDistrictId?: Maybe<Scalars['Int']>;
  websites?: Maybe<DistrictWebsites>;
  zone?: Maybe<Scalars['Int']>;
  zoneSection?: Maybe<Scalars['String']>;
};

export type DistrictDashboardsResults = {
  __typename?: 'DistrictDashboardsResults';
  asOfDate: Scalars['String'];
  clubDevelopment: Array<Maybe<ClubDevelopment>>;
  contribution: Contribution;
  demographics: Array<Maybe<Demographic>>;
  membersTrends: Array<Maybe<MembersTrends>>;
};

export type DistrictData = {
  __typename?: 'DistrictData';
  id: Scalars['String'];
  riDistrictId: Scalars['Int'];
};

export type DistrictDetailsInput = {
  businessAddress?: Maybe<Array<Maybe<DistrictAddressInput>>>;
  districtId: Scalars['String'];
  emails?: Maybe<DistrictEmailsInput>;
  localizedAddress?: Maybe<DistrictAddressInput>;
  mailingAddress?: Maybe<DistrictAddressInput>;
  otherFaxes?: Maybe<Array<Maybe<DistrictFaxInput>>>;
  otherPhones?: Maybe<Array<Maybe<DistrictPhoneInput>>>;
  otherWebsites?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryEmail?: Maybe<DistrictEmailInput>;
  primaryFax?: Maybe<DistrictFaxInput>;
  primaryPhone?: Maybe<DistrictPhoneInput>;
  primaryWebsite?: Maybe<Scalars['String']>;
  websites?: Maybe<DistrictWebsiteInput>;
};

export type DistrictEmailInput = {
  action?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  type: EmailType;
};

export type DistrictEmails = {
  __typename?: 'DistrictEmails';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
};

export type DistrictEmailsInput = {
  action?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  primaryEmail?: Maybe<Scalars['String']>;
};

export type DistrictFax = {
  __typename?: 'DistrictFax';
  country?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

export type DistrictFaxInput = {
  action?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number: Scalars['String'];
};

export type DistrictLeadership = {
  __typename?: 'DistrictLeadership';
  districtId?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  id: Scalars['String'];
  riDistrictId: Scalars['String'];
  role: Scalars['String'];
  roleId: Scalars['String'];
  startDate: Scalars['String'];
  termYears?: Maybe<Scalars['Int']>;
};

export type DistrictLeadershipResult = {
  __typename?: 'DistrictLeadershipResult';
  error?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type DistrictMembersStatistics = {
  __typename?: 'DistrictMembersStatistics';
  memberType: Scalars['String'];
  totalCount: Scalars['Int'];
};

export type DistrictMembershipClub = {
  __typename?: 'DistrictMembershipClub';
  clubId: Scalars['String'];
  clubName: Scalars['String'];
  clubType: Scalars['String'];
  id: Scalars['String'];
  location?: Maybe<LocationData>;
  membershipType: Scalars['String'];
  terminationDate?: Maybe<Scalars['String']>;
};

export type DistrictOfficer = {
  __typename?: 'DistrictOfficer';
  endDate: Scalars['String'];
  id: Scalars['String'];
  individual: OfficerIndividual;
  isDelegated?: Maybe<Scalars['Boolean']>;
  role: Scalars['String'];
  roleId: Scalars['String'];
  startDate: Scalars['String'];
};

export type DistrictOfficersResults = {
  __typename?: 'DistrictOfficersResults';
  /** Object containing all results returned from the search.  */
  leadership: Array<Maybe<DistrictOfficer>>;
  /** Total number of results.  */
  totalCount: Scalars['Int'];
};

export type DistrictPhone = {
  __typename?: 'DistrictPhone';
  country: Scalars['String'];
  countryId: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number: Scalars['String'];
};

export type DistrictPhoneInput = {
  action?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number: Scalars['String'];
};

export type DistrictType = {
  __typename?: 'DistrictType';
  id: Scalars['String'];
  isHomeDistrict: Scalars['Boolean'];
  riDistrictId: Scalars['Int'];
  zone?: Maybe<Scalars['String']>;
};

export type DistrictWebsiteInput = {
  action?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  primaryWebsite?: Maybe<Scalars['String']>;
};

export type DistrictWebsites = {
  __typename?: 'DistrictWebsites';
  id?: Maybe<Scalars['String']>;
  otherWebsites?: Maybe<Scalars['String']>;
  primaryWebsite?: Maybe<Scalars['String']>;
};

export type DistrictsResult = {
  __typename?: 'DistrictsResult';
  districts: Array<DistrictType>;
  error?: Maybe<Scalars['String']>;
};

export type DominoContactInfo = {
  address?: Maybe<Array<DominoContactInfoAddress>>;
  email?: Maybe<Array<DominoContactInfoEmail>>;
  phone?: Maybe<Array<DominoContactInfoPhone>>;
};

export type DominoContactInfoAddress = {
  action?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  hasStates?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  internationalProvince?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lineOne: Scalars['String'];
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  type?: Maybe<AddressType>;
};

export type DominoContactInfoEmail = {
  action?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  isSignin?: Maybe<Scalars['Boolean']>;
  type: EmailType;
};

export type DominoContactInfoPhone = {
  action?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  number: Scalars['String'];
  type: PhoneType;
};

export type DominoEmailInput = {
  action?: Maybe<Scalars['String']>;
  /** Club email */
  email?: Maybe<Scalars['String']>;
  /** Email identifier */
  id?: Maybe<Scalars['String']>;
  /** Club email is primary */
  isPrimary?: Maybe<Scalars['Boolean']>;
};

export type DominoEmailType = {
  __typename?: 'DominoEmailType';
  /** Club email */
  email: Scalars['String'];
  /** Email identifier */
  id: Scalars['String'];
  /** Club email is primary */
  isPrimary: Scalars['Boolean'];
};

/** Represents ECardDetailsResult. */
export type ECardDetailsResult = {
  __typename?: 'ECardDetailsResult';
  Created?: Maybe<Scalars['String']>;
  DedicatedTo?: Maybe<Scalars['String']>;
  DedicationType?: Maybe<Scalars['Int']>;
  DonorName?: Maybe<Scalars['String']>;
  ImageFid?: Maybe<Scalars['Int']>;
  LookupKey?: Maybe<Scalars['String']>;
  NotificationType?: Maybe<Scalars['Int']>;
  PersonalMsg?: Maybe<Scalars['String']>;
};

export type EditDelegationInput = {
  delegatedRole: Scalars['String'];
  delegateeEmail: Scalars['String'];
  delegateeIndividualId: Scalars['String'];
  delegateeMemberId: Scalars['String'];
  delegateeOrgTypeName: Scalars['String'];
  delegateePrefLangCode: Scalars['String'];
  delegationKey: Scalars['String'];
  endDate: Scalars['String'];
  memberId: Scalars['String'];
  startDate: Scalars['String'];
};

/** Input for the editMeeting mutation */
export type EditMeetingInput = {
  /** Club Id */
  clubId: Scalars['String'];
  /** Domino Id */
  dominoId?: Maybe<Scalars['String']>;
  /** Meeting */
  meeting: MeetingInput;
  /** MeetingId */
  meetingId: Scalars['String'];
};

export type EditMeetingResult = {
  __typename?: 'EditMeetingResult';
  /** Updated meeting */
  meeting?: Maybe<Meeting>;
  /** Updated meeting id */
  meetingId?: Maybe<Scalars['String']>;
};

export type Email = {
  __typename?: 'Email';
  address: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  /** Only vailable if _this_ individual is currently logged in into the app. Otherwise it's null. */
  isSignin?: Maybe<Scalars['Boolean']>;
  type: EmailType;
};

export type EmailAddress = {
  __typename?: 'EmailAddress';
  EmailAddress?: Maybe<Scalars['String']>;
  EmailType?: Maybe<Scalars['String']>;
  IsPrimary?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
};

/** An email address with metadata. */
export type EmailInput = {
  /** The email address. */
  address: Scalars['String'];
  /** Email type. One of Personal, Business. If not provided, set to Personal. */
  type: EmailType;
};

export enum EmailType {
  Business = 'Business',
  Personal = 'Personal'
}

export type ErrorAssignmentsResult = {
  __typename?: 'ErrorAssignmentsResult';
  body?: Maybe<ErrorBody>;
  id: Scalars['Int'];
  statusCode: Scalars['Int'];
};

export type ErrorBatch = {
  __typename?: 'ErrorBatch';
  code: Scalars['String'];
  description: Scalars['String'];
  source: Scalars['String'];
};

export type ErrorBody = {
  __typename?: 'ErrorBody';
  detail: Scalars['String'];
  errors?: Maybe<Array<ErrorBatch>>;
  status: Scalars['Int'];
  title: Scalars['String'];
  type: Scalars['String'];
};

/** Represents exchange rates. */
export type ExchangeRates = {
  __typename?: 'ExchangeRates';
  /** exchange rates */
  FromCurrency?: Maybe<Scalars['String']>;
  RateDivisor?: Maybe<Scalars['String']>;
  RateMultiplier?: Maybe<Scalars['String']>;
  ToCurrency?: Maybe<Scalars['String']>;
};

export type Expertise = {
  __typename?: 'Expertise';
  area: Scalars['String'];
  areaId: Scalars['String'];
  level: Scalars['String'];
  levelId: Scalars['String'];
};

export type ExpertiseArea = {
  __typename?: 'ExpertiseArea';
  areaId: Scalars['String'];
  label: Scalars['String'];
  primary: Scalars['Boolean'];
};

export type ExpertiseAreaItem = {
  __typename?: 'ExpertiseAreaItem';
  id: Scalars['String'];
  label: Scalars['String'];
};

export type ExpertiseInput = {
  areaId: Scalars['String'];
  levelId: Scalars['String'];
};

export type ExpertiseLevel = {
  __typename?: 'ExpertiseLevel';
  areas: Array<ExpertiseArea>;
  level: Scalars['String'];
  levelId: Scalars['String'];
};

export type ExpertiseLevelItem = {
  __typename?: 'ExpertiseLevelItem';
  id: Scalars['String'];
  label: Scalars['String'];
};

/** A Fax number */
export type Fax = {
  __typename?: 'Fax';
  country?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  type?: Maybe<FaxType>;
};

export type FaxInput = {
  action?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  type?: Maybe<FaxType>;
};

export enum FaxType {
  Business = 'Business',
  Home = 'Home'
}

export type Feedback = {
  __typename?: 'Feedback';
  feedbackType: Scalars['String'];
  id: Scalars['String'];
  isDistrictPresRep?: Maybe<Scalars['Boolean']>;
  questions: Array<Question>;
  responses: Array<QuestionResponse>;
};

export type FeedbackQuestionsResponse = {
  __typename?: 'FeedbackQuestionsResponse';
  id: Scalars['String'];
  questions: Array<Question>;
  sectionName: Scalars['String'];
};

export type FeedbackSection = {
  __typename?: 'FeedbackSection';
  id: Scalars['String'];
  sectionName: Scalars['String'];
};

export enum FieldCharSet {
  OnlyDigits = 'OnlyDigits',
  /** DIS regex: `/^[\\ -\\ɏ]*$/`. */
  OnlyLatin = 'OnlyLatin'
}

export type FieldConstraints = {
  __typename?: 'FieldConstraints';
  charSet?: Maybe<FieldCharSet>;
  maxLength: Scalars['Int'];
};

export type FieldOption = {
  __typename?: 'FieldOption';
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum FieldType {
  Date = 'Date',
  Number = 'Number',
  Options = 'Options',
  String = 'String'
}

export type FileContentResult = {
  __typename?: 'FileContentResult';
  FileContent?: Maybe<Scalars['String']>;
};

/** Represents a Fiscal Code by Member ID. */
export type FiscalCodeResult = {
  __typename?: 'FiscalCodeResult';
  /** Fiscal Code */
  FiscalCode_c?: Maybe<Scalars['String']>;
};

export type FormattedClub = {
  __typename?: 'FormattedClub';
  clubId: Scalars['String'];
  location: FormattedLocation;
  name: Scalars['String'];
  type: Scalars['String'];
};

export type FormattedLocation = {
  __typename?: 'FormattedLocation';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  internationalProvince?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type FormattedMembership = {
  __typename?: 'FormattedMembership';
  fullName: Scalars['String'];
  individualId: Scalars['String'];
  isTerminated: Scalars['Boolean'];
  startDate: Scalars['Int'];
  type: Scalars['String'];
};

export type FundSubtitle = {
  __typename?: 'FundSubtitle';
  de?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  ja?: Maybe<Scalars['String']>;
  ko?: Maybe<Scalars['String']>;
  pt?: Maybe<Scalars['String']>;
};

export type FundTitle = {
  __typename?: 'FundTitle';
  de?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  es?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  it?: Maybe<Scalars['String']>;
  ja?: Maybe<Scalars['String']>;
  ko?: Maybe<Scalars['String']>;
  pt?: Maybe<Scalars['String']>;
};

export type Funding = {
  __typename?: 'Funding';
  AmountExpected?: Maybe<Scalars['Float']>;
  AmountReceived?: Maybe<Scalars['Float']>;
  AmountRemaining?: Maybe<Scalars['Float']>;
  Budget?: Maybe<Scalars['Float']>;
  Paid?: Maybe<Scalars['Boolean']>;
};

/** Represents funds. */
export type Funds = {
  __typename?: 'Funds';
  alias?: Maybe<Scalars['String']>;
  fundId?: Maybe<Scalars['String']>;
  fundSubId?: Maybe<Scalars['String']>;
  fundSubtitle?: Maybe<FundSubtitle>;
  fundTitle?: Maybe<FundTitle>;
  /** funds */
  id?: Maybe<Scalars['Int']>;
  isPaymentType?: Maybe<Scalars['Boolean']>;
  recurring?: Maybe<Scalars['Boolean']>;
  rnwPurposeText?: Maybe<Scalars['String']>;
  typeName?: Maybe<Scalars['String']>;
};

export enum Gender {
  Dni = 'DNI',
  Female = 'Female',
  Male = 'Male',
  NotGiven = 'NotGiven',
  Self = 'Self'
}

export type GeoLocation = {
  __typename?: 'GeoLocation';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type GetAccessLevelsResults = {
  __typename?: 'GetAccessLevelsResults';
  arguments?: Maybe<AccessLevelsArguments>;
  /** User's access level for an DIS API url. */
  level: AccessLevel;
  /** DIS API url */
  target: Scalars['String'];
};

export type GetAddDelegationResult = {
  __typename?: 'GetAddDelegationResult';
  DelegationKey?: Maybe<Scalars['String']>;
};

export type GetAllDistrictOfficersOptions = {
  shouldFetchDominoOfficers?: Maybe<Scalars['Boolean']>;
  shouldFetchNonMemberDES?: Maybe<Scalars['Boolean']>;
  shouldGetMembershipsFromOtherDistricts?: Maybe<Scalars['Boolean']>;
};

export type GetConferenceDetailsResult = {
  __typename?: 'GetConferenceDetailsResult';
  comments?: Maybe<Scalars['String']>;
  conferenceAddress?: Maybe<ConferenceAddress>;
  createdByRiDistrictId?: Maybe<Scalars['String']>;
  detailForJoiningRemotely?: Maybe<Scalars['String']>;
  districtId: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isPresRepRequested?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  locationNameOrDetail?: Maybe<Scalars['String']>;
  onlineLocation?: Maybe<Scalars['String']>;
  participatingDistricts?: Maybe<Array<ParticipatingDistricts>>;
  prAccPhone?: Maybe<Scalars['String']>;
  prAccommodation?: Maybe<Scalars['String']>;
  prAddress?: Maybe<ConferenceAddress>;
  presRepAccPhone?: Maybe<AccPhone>;
  presidentialRepresentative?: Maybe<PresidentialRepresentative>;
  relatedRotaryYear: Scalars['Int'];
  startDate?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  venueName?: Maybe<Scalars['String']>;
  venueType: MeetingType;
};

export type GetConferencesResults = {
  __typename?: 'GetConferencesResults';
  conferenceAddress?: Maybe<ConferenceAddress>;
  createdByRiDistrictId?: Maybe<Scalars['String']>;
  districtId: Scalars['String'];
  endDate: Scalars['String'];
  feedbacks: Array<ConferenceFeedbackResult>;
  id: Scalars['String'];
  isDistrictPresRep?: Maybe<Scalars['Boolean']>;
  participatingDistricts?: Maybe<Array<ParticipatingDistricts>>;
  presidentialRepresentative?: Maybe<PresidentialRepresentative>;
  relatedRotaryYear: Scalars['Int'];
  startDate: Scalars['String'];
  venueType: MeetingType;
};

export type GetDelegationProfileResult = {
  __typename?: 'GetDelegationProfileResult';
  Delegates?: Maybe<Array<Maybe<Delegates>>>;
  Delegations?: Maybe<Array<Maybe<Delegations>>>;
  Roles?: Maybe<Array<DelegationRoles>>;
};

export type GetEditedDelegationResult = {
  __typename?: 'GetEditedDelegationResult';
  DelegationKey?: Maybe<Scalars['String']>;
};

export type GetPresRepConferencesResult = {
  __typename?: 'GetPresRepConferencesResult';
  conferences: Array<GetConferencesResults>;
  districtId: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  riDistrictId: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  term: Scalars['Int'];
};

export type GrantDetailsResult = {
  __typename?: 'GrantDetailsResult';
  EndDate?: Maybe<Scalars['String']>;
  Funding?: Maybe<Funding>;
  Id?: Maybe<Scalars['String']>;
  Location?: Maybe<GrantLocation>;
  Message?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ProgramYear?: Maybe<Scalars['Int']>;
  StartDate?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

/** Represents grant details by GrantId. */
export type GrantLocation = {
  __typename?: 'GrantLocation';
  GrantCountry?: Maybe<Scalars['String']>;
};

export type GroupApplicationRolesRelationshipType = {
  __typename?: 'GroupApplicationRolesRelationshipType';
  accessFlag?: Maybe<Scalars['String']>;
  applications?: Maybe<Array<Maybe<GroupApplicationsType>>>;
  containErrors?: Maybe<Scalars['Boolean']>;
  errorCode?: Maybe<Scalars['Int']>;
  errorId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<GroupRolesType>>>;
};

export type GroupApplicationsType = {
  __typename?: 'GroupApplicationsType';
  appInstanceId?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GroupRolesType = {
  __typename?: 'GroupRolesType';
  roleKey?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
};

export type HateoasLink = {
  __typename?: 'HATEOASLink';
  href: Scalars['String'];
  method: Scalars['String'];
};

/**
 * Individual object for the getIndividual query,
 * with IndividualSearchResult properties.
 */
export type Individual = {
  __typename?: 'Individual';
  aboutMe?: Maybe<Scalars['String']>;
  /**
   * The list contains only the active club affiliations.
   * The list is sorted and grouped with the following order: full membership
   * (only one item), officer roles, honorary memberships. Within the groups, the
   * list is sorted alphabetically by the club name.
   */
  clubAffiliations: Array<ClubAffiliation>;
  dateOfBirth?: Maybe<Scalars['String']>;
  /** The edit permission rights for the requesting user. */
  editPermissions?: Maybe<IndividualEditPermissions>;
  /** Individual's primary email address. */
  email?: Maybe<Scalars['String']>;
  /** Individual's first name. */
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  genderDescription?: Maybe<Scalars['String']>;
  groupedExpertise: Array<ExpertiseLevel>;
  /** The HATEOAS links collection */
  hateoasLinks?: Maybe<Array<HateoasLink>>;
  /** Individual's id. */
  id: Scalars['String'];
  /** Flag that indicates if current user is a club officer */
  isClubOfficer?: Maybe<Scalars['Boolean']>;
  /** Flag that indicates if current user is a club officer or district officer */
  isClubOrDistrictOfficer?: Maybe<Scalars['Boolean']>;
  /** Individual's last name. */
  lastName?: Maybe<Scalars['String']>;
  /** Individual's localized name. */
  localizedName?: Maybe<Scalars['String']>;
  /** Individual's location. */
  location?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  /** Individual's Full name. */
  name: Scalars['String'];
  nfKey?: Maybe<Scalars['String']>;
  onlineId?: Maybe<Scalars['String']>;
  otherAddresses?: Maybe<Array<Address>>;
  otherEmails?: Maybe<Array<Email>>;
  otherExpertise?: Maybe<Array<Expertise>>;
  otherLanguages?: Maybe<Array<IndividualLanguage>>;
  otherPhones?: Maybe<Array<PhoneNumber>>;
  otherProfessions?: Maybe<Array<Profession>>;
  phoneNumber: Scalars['String'];
  /** The URL for individual's photo. */
  photoUri?: Maybe<Scalars['String']>;
  preferredLanguage: Scalars['String'];
  prefix?: Maybe<Scalars['String']>;
  /** Individual's primary address. */
  primaryAddress?: Maybe<Address>;
  /** Individual's primary email. */
  primaryEmail?: Maybe<Email>;
  primaryExpertise?: Maybe<Expertise>;
  primaryLanguage: Scalars['String'];
  primaryPhone?: Maybe<PhoneNumber>;
  primaryProfession?: Maybe<Profession>;
  programs: Array<Program>;
  recognitionOptOutFlag?: Maybe<Scalars['Boolean']>;
  /** Indicates whether user have requested their personal information to not be shown. */
  restrictInfo?: Maybe<Scalars['Boolean']>;
  /** Indicates whether user have requested their photo to not be shown. */
  restrictPhoto?: Maybe<Scalars['Boolean']>;
  resumeRecognitions: Array<RecognitionItem>;
  /**
   * The individual's roles grouped by organizations.
   * The list is ordered descending by the biggest startDate within the organization roles.
   * Roles are both membership and leadership roles.
   */
  resumeRoles: ResumeRoles;
  resumeSponsorships: SponsorshipResult;
  /** Individual's riId. */
  riIndividualId?: Maybe<Scalars['Int']>;
  /** The extended permissions are only available if user can edit them. */
  sharingPermissionsExtended?: Maybe<SharingPermissionsExtended>;
  /** The regular permissions are only available if extended permissions are missing. */
  sharingPermissionsRegular?: Maybe<SharingPermissionsRegular>;
  suffix?: Maybe<Scalars['String']>;
  yearOfBirth?: Maybe<Scalars['Int']>;
  youthFlag?: Maybe<Scalars['Boolean']>;
};


/**
 * Individual object for the getIndividual query,
 * with IndividualSearchResult properties.
 */
export type IndividualClubAffiliationsArgs = {
  filters?: Maybe<ClubAffiliationFilters>;
};


/**
 * Individual object for the getIndividual query,
 * with IndividualSearchResult properties.
 */
export type IndividualResumeSponsorshipsArgs = {
  profileIndividualId: Scalars['String'];
};

export type IndividualAddress = {
  __typename?: 'IndividualAddress';
  AddressLine1?: Maybe<Scalars['String']>;
  AddressLine2?: Maybe<Scalars['String']>;
  AddressLine3?: Maybe<Scalars['String']>;
  /** Member Address Details */
  AddressType?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  InternationalProvince?: Maybe<Scalars['String']>;
  IsPrimary?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  MemberId?: Maybe<Scalars['Int']>;
  PostalCode?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
};

export type IndividualAffiliationInfo = {
  __typename?: 'IndividualAffiliationInfo';
  clubs: Array<ShortClubInfo>;
  districts: Array<DesDistrictInfo>;
};

export type IndividualDesResult = {
  __typename?: 'IndividualDESResult';
  affiliationInfo?: Maybe<IndividualAffiliationInfo>;
};

export type IndividualDetails = {
  __typename?: 'IndividualDetails';
  FirstName?: Maybe<Scalars['String']>;
  LastName?: Maybe<Scalars['String']>;
  LocalizedName?: Maybe<Scalars['String']>;
  MemberToken?: Maybe<Scalars['String']>;
};

export type IndividualEditPermissions = {
  __typename?: 'IndividualEditPermissions';
  areasOfExpertise?: Maybe<Scalars['Boolean']>;
  background?: Maybe<Scalars['Boolean']>;
  contactInformation?: Maybe<Scalars['Boolean']>;
  photo?: Maybe<Scalars['Boolean']>;
  professionalExperience?: Maybe<Scalars['Boolean']>;
  recognition?: Maybe<Scalars['Boolean']>;
  rotaryPrograms?: Maybe<Scalars['Boolean']>;
};

export type IndividualEmail = {
  __typename?: 'IndividualEmail';
  EmailAddress?: Maybe<Scalars['String']>;
  EmailType?: Maybe<Scalars['String']>;
  IsOnlineId?: Maybe<Scalars['Boolean']>;
  IsPrimary?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  MemberId?: Maybe<Scalars['Int']>;
};

export type IndividualInfo = {
  __typename?: 'IndividualInfo';
  BadgeName?: Maybe<Scalars['String']>;
  Classification?: Maybe<Scalars['String']>;
  DateOfBirth?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  Gender?: Maybe<Scalars['String']>;
  GenderDescription?: Maybe<Scalars['String']>;
  Key?: Maybe<Scalars['String']>;
  LastName?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  LocalizedName?: Maybe<Scalars['String']>;
  /** Member Details */
  MemberId?: Maybe<Scalars['Int']>;
  MiddleName?: Maybe<Scalars['String']>;
  Prefix?: Maybe<Scalars['String']>;
  PrimaryLanguage?: Maybe<Scalars['String']>;
  Suffix?: Maybe<Scalars['String']>;
  YouthFlag?: Maybe<Scalars['Boolean']>;
};

export type IndividualLanguage = {
  __typename?: 'IndividualLanguage';
  language: Scalars['String'];
  languageId: Scalars['String'];
};

/** Represents Member Details based on parameters : MemberId. */
export type IndividualMemberDetailsResult = {
  __typename?: 'IndividualMemberDetailsResult';
  BadgeName?: Maybe<Scalars['String']>;
  Classification?: Maybe<Scalars['String']>;
  DateOfBirth?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  Gender?: Maybe<Scalars['String']>;
  GenderDescription?: Maybe<Scalars['String']>;
  Key?: Maybe<Scalars['String']>;
  LastName?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  LocalizedName?: Maybe<Scalars['String']>;
  /** Member Details */
  MemberId?: Maybe<Scalars['Int']>;
  MiddleName?: Maybe<Scalars['String']>;
  Prefix?: Maybe<Scalars['String']>;
  PrimaryLanguage?: Maybe<Scalars['String']>;
  Suffix?: Maybe<Scalars['String']>;
  YouthFlag?: Maybe<Scalars['Boolean']>;
};

/** Current user's access levels for operations on members. */
export type IndividualOperationsAccessGql = {
  __typename?: 'IndividualOperationsAccessGql';
  assignRole?: Maybe<AccessLevel>;
  editMember?: Maybe<AccessLevel>;
  editMembershipType?: Maybe<AccessLevel>;
  terminateMembership?: Maybe<AccessLevel>;
};

export type IndividualOrganization = {
  __typename?: 'IndividualOrganization';
  Id?: Maybe<Scalars['Int']>;
  Key?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type IndividualPhone = {
  __typename?: 'IndividualPhone';
  CountryCode?: Maybe<Scalars['String']>;
  IsPrimary?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  MemberId?: Maybe<Scalars['Int']>;
  PhoneCountryName?: Maybe<Scalars['String']>;
  PhoneExtension?: Maybe<Scalars['String']>;
  PhoneNumber?: Maybe<Scalars['String']>;
  PhoneType?: Maybe<Scalars['String']>;
};

export type IndividualPhoneInput = {
  /** Id of the country as returned by the countries field. */
  countryId: Scalars['String'];
  /** Only allows digits, spaces, dashs, slashes, commas parentheses and dots Can ONLY start with either a numeric or open parentheses. Maximum length is 10. */
  extension?: Maybe<Scalars['String']>;
  /** Only allows digits, spaces, dashs, slashes, commas parentheses and dots. Can ONLY start with either a numeric or open parentheses. Number/extension/type combination must not already exist for this individual. Duplicate checking must remove all non-numeric characters. Length limit is between 1 and 30. */
  number: Scalars['String'];
  /** Phone type. Must be one of Business, Home, Mobile. If not provided, set to Mobile. */
  type: PhoneType;
};

/** Represents Member Details based on parameters : MemberId. */
export type IndividualProfileDetailsResult = {
  __typename?: 'IndividualProfileDetailsResult';
  Address?: Maybe<Array<Maybe<IndividualAddress>>>;
  Email?: Maybe<Array<Maybe<IndividualEmail>>>;
  Individual?: Maybe<IndividualInfo>;
  Language?: Maybe<Array<Maybe<LanguageDetails>>>;
  Phone?: Maybe<Array<Maybe<IndividualPhone>>>;
};

/** Represents Member Role Details based on Organiazation. */
export type IndividualRole = {
  __typename?: 'IndividualRole';
  IsDelegated?: Maybe<Scalars['Boolean']>;
  IsUnverified?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  /** Member Role Details */
  Name?: Maybe<Scalars['String']>;
  RoleEventKey?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
};

/** Member search result object, as they are returned for search query */
export type IndividualSearchResult = {
  __typename?: 'IndividualSearchResult';
  activeLeaderships: Array<Leadership>;
  /** Can have only one Member, but multiple Honorary. */
  activeMemberships: Array<ActiveMembership>;
  /** Individual's primary address. */
  address?: Maybe<Address>;
  /** Individual's primary email address. */
  email?: Maybe<Scalars['String']>;
  /** Individual's UUID. */
  id: Scalars['String'];
  /** Individual's location. */
  location?: Maybe<Scalars['String']>;
  /** Individual's Full name. */
  name: Scalars['String'];
  /**
   * The members phone number, if visible.
   * Format: +[prefix] [number]
   */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The URL for individual's photo. */
  photoUri?: Maybe<Scalars['String']>;
  /** Individual's riIndividualId. */
  riIndividualId?: Maybe<Scalars['String']>;
  /** fields that shared with me if I am club officer */
  sharingPermission?: Maybe<SharingPermissionResult>;
};


/** Member search result object, as they are returned for search query */
export type IndividualSearchResultActiveLeadershipsArgs = {
  clubId?: Maybe<Scalars['String']>;
  riClubId?: Maybe<Scalars['String']>;
};


/** Member search result object, as they are returned for search query */
export type IndividualSearchResultActiveMembershipsArgs = {
  clubId?: Maybe<Scalars['String']>;
  riClubId?: Maybe<Scalars['String']>;
};

/** Member search result object, as they are returned for search query */
export type IndividualSearchResults = {
  __typename?: 'IndividualSearchResults';
  /** Object containing all results returned from the search.  */
  results: Array<IndividualSearchResult>;
  /** Total number of results.  */
  totalCount: Scalars['Int'];
};

/** Represents Security group Details based on parameters : MemberId. */
export type IndividualSecurityGroupsResult = {
  __typename?: 'IndividualSecurityGroupsResult';
  Key?: Maybe<Scalars['String']>;
  /** Security group Details */
  Name?: Maybe<Scalars['String']>;
  Organizations?: Maybe<Array<Maybe<IndividualOrganization>>>;
  Roles?: Maybe<Array<Maybe<IndividualRole>>>;
  TermAccessStatus?: Maybe<Scalars['String']>;
};

export type InputAccomodationPhone = {
  countryId: Scalars['String'];
  extention?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  type: Scalars['String'];
};

export type InputConferenceAddress = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type InputCreateConference = {
  comments?: Maybe<Scalars['String']>;
  conferenceAddress?: Maybe<InputConferenceAddress>;
  detailForJoiningRemotely?: Maybe<Scalars['String']>;
  districtId: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  isPresRepRequested?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  onlineLocation?: Maybe<Scalars['String']>;
  participatingDistricts?: Maybe<Array<ParticipatingDistrictsInput>>;
  prAccommodation?: Maybe<Scalars['String']>;
  prAddress?: Maybe<InputConferenceAddress>;
  presRepAccPhone?: Maybe<InputAccomodationPhone>;
  startDate?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  venueName?: Maybe<Scalars['String']>;
  venueType: Scalars['String'];
};

export type InputCreateFeedback = {
  conferenceId: Scalars['String'];
  feedbackType: Scalars['String'];
  questions: Array<InputQuestion>;
  questionsResponse: Array<InputQuestionResponse>;
};

export type InputFeedbackSection = {
  id: Scalars['String'];
  sectionName: Scalars['String'];
};

export type InputOptionDetail = {
  id: Scalars['String'];
  optionValue: Scalars['String'];
};

export type InputOptionType = {
  id: Scalars['String'];
  optionType: Scalars['String'];
};

export type InputQuestion = {
  applicationFeature: Scalars['String'];
  applicationId: Scalars['String'];
  feedbackSection: InputFeedbackSection;
  id: Scalars['String'];
  option: Scalars['String'];
  optionDataType: Scalars['String'];
  optionDetail: InputOptionDetail;
  optionSeq: Scalars['Int'];
  optionType: InputOptionType;
};

export type InputQuestionResponse = {
  questionId: Scalars['String'];
  value: Scalars['String'];
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  amount: Scalars['String'];
  checked: Scalars['Boolean'];
  date: Scalars['String'];
  description: Scalars['String'];
  exchangeRateBillingtoUSD: Scalars['String'];
  exchangeRateLocaltoUSD: Scalars['String'];
  exchangeRateUSDtoBilling: Scalars['String'];
  exchangeRateUSDtoLocal: Scalars['String'];
  itemNumber: Scalars['String'];
};

export type LeLegalEntityTranslations = {
  __typename?: 'LELegalEntityTranslations';
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  LanguageCode?: Maybe<Scalars['String']>;
  LegalEntityKey?: Maybe<Scalars['String']>;
  LegalEntityTranslationsKey?: Maybe<Scalars['String']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
  TranslatedName?: Maybe<Scalars['String']>;
};

/** Represents a language. */
export type Language = {
  __typename?: 'Language';
  /** The id of the language. */
  id: Scalars['String'];
  /** The name of the language. */
  name: Scalars['String'];
};

export type LanguageDetails = {
  __typename?: 'LanguageDetails';
  IsPrimary?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  LanguageName?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  MemberId?: Maybe<Scalars['Int']>;
  Proficiency?: Maybe<Scalars['String']>;
  Read?: Maybe<Scalars['Boolean']>;
  Speak?: Maybe<Scalars['Boolean']>;
};

/** Represents a language. */
export type LanguageExtended = {
  __typename?: 'LanguageExtended';
  /** The id of the language. */
  id: Scalars['String'];
  /** The name of the language. */
  name: Scalars['String'];
  /** Two letter code of the language. This one should NOT be used when writing data to DIS. */
  shortCode: Scalars['String'];
};

export type LanguageInput = {
  isPrimary: Scalars['Boolean'];
  languageId: Scalars['String'];
};

export type Leadership = {
  __typename?: 'Leadership';
  clubId: Scalars['String'];
  clubName: Scalars['String'];
  /** District ID. */
  clubType: Scalars['String'];
  districtId: Scalars['String'];
  endDate: Scalars['String'];
  id: Scalars['String'];
  role: Scalars['String'];
  roleId: Scalars['String'];
  startDate: Scalars['String'];
};

export type LeadershipAffiliation = {
  __typename?: 'LeadershipAffiliation';
  isDelegated?: Maybe<Scalars['Boolean']>;
  leadershipRole: Scalars['String'];
};

export type LeadershipEmbeddedDataBase = {
  __typename?: 'LeadershipEmbeddedDataBase';
  organization?: Maybe<OrganisationDataBase>;
};

export type LeadershipResponseResult = {
  __typename?: 'LeadershipResponseResult';
  _embedded?: Maybe<LeadershipEmbeddedDataBase>;
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDelegated?: Maybe<Scalars['Boolean']>;
  role: Scalars['String'];
  roleId: Scalars['String'];
  startDate: Scalars['String'];
};

export type LegalEntity = {
  __typename?: 'LegalEntity';
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  LegalEntityAbbr?: Maybe<Scalars['String']>;
  LegalEntityKey?: Maybe<Scalars['String']>;
  LegalEntityName?: Maybe<Scalars['String']>;
  LegalEntityTranslations?: Maybe<Array<Maybe<LegalEntityTranslations>>>;
  ModifiedByUser?: Maybe<Scalars['String']>;
};

/** Represents LegalEntitiesResult. */
export type LegalEntityResult = {
  __typename?: 'LegalEntityResult';
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  LegalEntityAbbr?: Maybe<Scalars['String']>;
  LegalEntityKey?: Maybe<Scalars['String']>;
  LegalEntityName?: Maybe<Scalars['String']>;
  LegalEntityTranslations?: Maybe<Array<Maybe<LeLegalEntityTranslations>>>;
  ModifiedByUser?: Maybe<Scalars['String']>;
};

export type LegalEntityTranslations = {
  __typename?: 'LegalEntityTranslations';
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  LanguageCode?: Maybe<Scalars['String']>;
  LegalEntityKey?: Maybe<Scalars['String']>;
  LegalEntityTranslationsKey?: Maybe<Scalars['String']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
  TranslatedName?: Maybe<Scalars['String']>;
};

export type Link = {
  __typename?: 'Link';
  href?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
};

export type Links = {
  __typename?: 'Links';
  individual?: Maybe<Link>;
};

export type Location = {
  __typename?: 'Location';
  Country?: Maybe<Scalars['String']>;
  InternationalProvince?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
};

export type LocationData = {
  __typename?: 'LocationData';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryId: Scalars['String'];
  internationalProvince?: Maybe<Scalars['String']>;
  lineOne?: Maybe<Scalars['String']>;
  lineThree?: Maybe<Scalars['String']>;
  lineTwo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
};

export type LocationDetails = {
  __typename?: 'LocationDetails';
  /** Location Details */
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  InternationalProvince?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
};

export type LocationInfo = {
  __typename?: 'LocationInfo';
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  InternationalProvince?: Maybe<Scalars['String']>;
};

export enum LogLevel {
  Debug = 'debug',
  Error = 'error',
  Info = 'info',
  Warn = 'warn'
}

/** The returned object for a meetings, when retrieving a club. */
export type Meeting = {
  __typename?: 'Meeting';
  address?: Maybe<BaseAddress>;
  comment?: Maybe<Scalars['String']>;
  detailForJoiningRemotely?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  languageId?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationNameOrDetail?: Maybe<Scalars['String']>;
  locationVaries: Scalars['Boolean'];
  longitude?: Maybe<Scalars['Float']>;
  meetingId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  onlineLocation?: Maybe<Scalars['String']>;
  time: Scalars['String'];
  type: MeetingType;
  weekday: WeekdayFull;
};

/** Input for the meetings field when using the updateClub mutation. */
export type MeetingInput = {
  address?: Maybe<BaseAddressInput>;
  comment?: Maybe<Scalars['String']>;
  detailForJoiningRemotely?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  languageId?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationNameOrDetail?: Maybe<Scalars['String']>;
  locationVaries?: Maybe<Scalars['Boolean']>;
  longitude?: Maybe<Scalars['Float']>;
  meetingId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  onlineLocation?: Maybe<Scalars['String']>;
  time: Scalars['String'];
  type: MeetingType;
  weekday: WeekdayFull;
};

export enum MeetingType {
  Hybrid = 'Hybrid',
  Online = 'Online',
  Physical = 'Physical'
}

export enum MeetingTypeFilterValue {
  Hybrid = 'Hybrid',
  Online = 'Online',
  Physical = 'Physical'
}

/** Represents Member Details based on parameters : MemberId. */
export type MemberOrganizationDetailsResult = {
  __typename?: 'MemberOrganizationDetailsResult';
  BadgeName?: Maybe<Scalars['String']>;
  Clubs?: Maybe<Array<Maybe<ClubDetails>>>;
  CreditOrg?: Maybe<CreditOrgDetails>;
  DigitalLanguage?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  IsDeceased?: Maybe<Scalars['Boolean']>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  IsYouth?: Maybe<Scalars['Boolean']>;
  /** Member Details */
  Key?: Maybe<Scalars['String']>;
  LastName?: Maybe<Scalars['String']>;
  Location?: Maybe<LocationDetails>;
  MemberId?: Maybe<Scalars['Int']>;
  MiddleName?: Maybe<Scalars['String']>;
  Prefix?: Maybe<Scalars['String']>;
  Suffix?: Maybe<Scalars['String']>;
};

/** Member search result object, as they are returned for search query */
export type MemberSearchResult = {
  __typename?: 'MemberSearchResult';
  activeLeaderships: Array<Leadership>;
  /** Active club affiliation by order, fist showing club aff from current district */
  activeMembershipInAllDistricts?: Maybe<ActiveMembership>;
  /** Can have only one Member, but multiple Honorary. */
  activeMemberships: Array<ActiveMembership>;
  /** Individual's primary address. */
  address?: Maybe<Address>;
  associatedClubsInfo: Array<Club>;
  clubMemberships: Array<ActiveMembership>;
  /** The member's deceased date. */
  deceasedDate?: Maybe<Scalars['String']>;
  /** Individual's primary email address. */
  email?: Maybe<Scalars['String']>;
  /** Individual's UUID. */
  id: Scalars['String'];
  /** Tells if a given member is deceased. */
  isDeceased?: Maybe<Scalars['Boolean']>;
  /** Individual's localized name. */
  localizedName?: Maybe<Scalars['String']>;
  /** Individual's location. */
  location?: Maybe<Scalars['String']>;
  /** Membership ID. Available when executed in the club context. */
  membershipId?: Maybe<Scalars['String']>;
  /** Individual's Full name. */
  name: Scalars['String'];
  /** Individual's Full name with prefix and suffix. */
  nameWithPrefixSuffix: Scalars['String'];
  onlineId?: Maybe<Scalars['String']>;
  /** Access levels for operations for the current user. */
  operationsAccess: IndividualOperationsAccessGql;
  otherEmails?: Maybe<Array<Scalars['String']>>;
  /**
   * The members phone number, if visible.
   * Format: +[prefix] [number]
   */
  phoneNumber?: Maybe<Scalars['String']>;
  /** The URL for individual's photo. */
  photoUri?: Maybe<Scalars['String']>;
  preferredLanguage?: Maybe<Language>;
  /** Individual's riIndividualId. */
  riIndividualId: Scalars['String'];
  /** Individual's sharing permission for fields name, email, phone etc. */
  sharingPermissionsResult: SharingPermissionsResult;
  thisClubLeadership: Array<Leadership>;
  thisDistrictLeadership: Array<DistrictLeadership>;
};


/** Member search result object, as they are returned for search query */
export type MemberSearchResultActiveLeadershipsArgs = {
  clubId?: Maybe<Scalars['String']>;
  riClubId?: Maybe<Scalars['String']>;
};


/** Member search result object, as they are returned for search query */
export type MemberSearchResultActiveMembershipInAllDistrictsArgs = {
  clubId?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  riClubId?: Maybe<Scalars['String']>;
};


/** Member search result object, as they are returned for search query */
export type MemberSearchResultActiveMembershipsArgs = {
  clubId?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  riClubId?: Maybe<Scalars['String']>;
};


/** Member search result object, as they are returned for search query */
export type MemberSearchResultAssociatedClubsInfoArgs = {
  district?: Maybe<Scalars['String']>;
};


/** Member search result object, as they are returned for search query */
export type MemberSearchResultClubMembershipsArgs = {
  clubId?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  riClubId?: Maybe<Scalars['String']>;
};


/** Member search result object, as they are returned for search query */
export type MemberSearchResultThisClubLeadershipArgs = {
  clubId?: Maybe<Scalars['String']>;
  riClubId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};


/** Member search result object, as they are returned for search query */
export type MemberSearchResultThisDistrictLeadershipArgs = {
  district?: Maybe<Scalars['String']>;
  includeDLHistory?: Maybe<Scalars['Boolean']>;
};

/** Member search result object, as they are returned for search query */
export type MemberSearchResults = {
  __typename?: 'MemberSearchResults';
  /** Object containing all results returned from the search.  */
  results: Array<MemberSearchResult>;
  /** Total number of results.  */
  totalCount: Scalars['Int'];
};

export type MembersTrends = {
  __typename?: 'MembersTrends';
  july1st: Scalars['Int'];
  monthlyData: Array<MonthlyData>;
  totalCount: Scalars['Int'];
  year: Scalars['Int'];
};

export type Membership = {
  __typename?: 'Membership';
  admissionDate: Scalars['String'];
  club: Club;
  id: Scalars['String'];
  individual: Individual;
  terminationDate?: Maybe<Scalars['String']>;
  type: MembershipType;
};

export type MembershipAffiliation = {
  __typename?: 'MembershipAffiliation';
  admissionDate: Scalars['String'];
  id: Scalars['String'];
  membershipType: MembershipType;
  terminationDate?: Maybe<Scalars['String']>;
};

export type MembershipEmbeddedDataBase = {
  __typename?: 'MembershipEmbeddedDataBase';
  sponsors?: Maybe<Array<Maybe<SubSponser>>>;
};

export type MembershipForAddMember = {
  __typename?: 'MembershipForAddMember';
  clubId: Scalars['String'];
  clubType: Scalars['String'];
  terminationDate?: Maybe<Scalars['String']>;
  type: MembershipType;
};

export type MembershipHistoryBase = {
  __typename?: 'MembershipHistoryBase';
  _embedded?: Maybe<MembershipEmbeddedDataBase>;
  _links?: Maybe<Links>;
  admissionDate: Scalars['String'];
  id: Scalars['String'];
  terminationDate?: Maybe<Scalars['String']>;
  terminationReason?: Maybe<Scalars['String']>;
  terminationReasonId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export enum MembershipStatusFilter {
  Active = 'Active',
  Deceased = 'Deceased',
  Honorary = 'Honorary',
  HonoraryRotaractor = 'Honorary_Rotaractor',
  Rotaractor = 'Rotaractor',
  Terminated = 'Terminated'
}

export enum MembershipType {
  Honorary = 'Honorary',
  Member = 'Member',
  RotaractHonorary = 'Rotaract_Honorary',
  Rotaractor = 'Rotaractor'
}

export type MembershipsList = {
  __typename?: 'MembershipsList';
  admissionDate?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  terminationDate?: Maybe<Scalars['String']>;
  terminationReason?: Maybe<Scalars['String']>;
  terminationReasonId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type MonthlyData = {
  __typename?: 'MonthlyData';
  count?: Maybe<Scalars['Int']>;
  fiscalMonth: Scalars['Int'];
  month: Scalars['String'];
};

export type MovedItem = {
  affiliationId: Scalars['String'];
  affiliationStartDate?: Maybe<Scalars['String']>;
  clubId: Scalars['String'];
  clubName: Scalars['String'];
  destinationId: Scalars['String'];
  destinationName: Scalars['String'];
  sourceId: Scalars['String'];
  sourceName: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Validates the token and returns the account data associated with it. Defined as mutation to avoid any possible caching. */
  accountInfoByToken: AccountInfoByToken;
  addClubMeeting: AddClubMeetingResult;
  addClubVendor: AddClubVendorResult;
  addDelegation: GetAddDelegationResult;
  /** Add a Ecard */
  addEcardDetails?: Maybe<EcardAddResult>;
  /** Add a Fiscal Code */
  addFiscalCode?: Maybe<FiscalCodeInfoResult>;
  /** Add a Pan */
  addPan?: Maybe<PanInfoResult>;
  /** Add a RRN */
  addRRN?: Maybe<RrnInfoResult>;
  /** Terminate a membership */
  addSponsor?: Maybe<Scalars['Void']>;
  assignDistrictLeadership: DistrictLeadershipResult;
  /** Changes the password of the currently logged in user. */
  changePassword?: Maybe<Scalars['Void']>;
  /** Changes the password of the currently logged in user. */
  changeSecurityQuestion?: Maybe<Scalars['Void']>;
  /** Sends the confirmation email for changing the SignIn email address. */
  changeSigninEmail?: Maybe<Scalars['Void']>;
  /** Submit ContactUs form and send email */
  contactUs?: Maybe<Scalars['Void']>;
  /** Creates an OKTA account and sends the activation email. */
  createAccount?: Maybe<Scalars['Void']>;
  createConference?: Maybe<Scalars['Void']>;
  createFeedback?: Maybe<Scalars['Void']>;
  createIndividual: CreateOrUpdateIndividualResult;
  /** Creates an individual from the logged in user. */
  createIndividualFromSession?: Maybe<Scalars['Void']>;
  createIndividualProgram: Program;
  /** Terminate a membership */
  createLeadership: CreateLeadershipResult;
  /** Terminate a membership */
  createMembership: CreateMembershipResult;
  deleteClubMeeting: DeleteClubMeetingResult;
  deleteConference?: Maybe<Scalars['Void']>;
  deleteDelegation?: Maybe<Scalars['Void']>;
  deleteDistrictLeadership: DistrictLeadershipResult;
  /** Returns the individualProgramId of the deleted individual program. */
  deleteIndividualProgram: Scalars['String'];
  /** Deletes the person's photo. */
  deletePhoto?: Maybe<Scalars['Void']>;
  editConference?: Maybe<Scalars['Void']>;
  editDelegation: GetEditedDelegationResult;
  editFeedback?: Maybe<Scalars['Void']>;
  editMeeting: EditMeetingResult;
  /** Finalizes the account creation process and activates it. */
  finalizeAccountCreation?: Maybe<Scalars['Void']>;
  /** Finalizes the change of the SignIn email. */
  finalizeChangeOfSigninEmail?: Maybe<Scalars['Void']>;
  mapByDisEmail: Scalars['Boolean'];
  moveAgClubAffiliations: ClubAssignmentsResponse;
  replaceAgClubAffiliations: AgResult;
  /** Re-sends the activation message with a fresh token. */
  resendActivationEmail?: Maybe<Scalars['Void']>;
  /** Reset any modified example data to its initial state. */
  reset?: Maybe<Scalars['Void']>;
  sendLog?: Maybe<Scalars['Void']>;
  /** Sends a password recovery message if the email is found. */
  sendPasswordResetMessage?: Maybe<Scalars['Void']>;
  terminateClubVendor: TerminateClubVendorResult;
  /** Terminate a Leadership */
  terminateLeadership?: Maybe<Scalars['Void']>;
  /** Terminate a membership */
  terminateMembership?: Maybe<Scalars['Void']>;
  updateAgClubAffiliations: AgResult;
  updateClub: UpdateClubResult;
  updateContactInfo: CreateOrUpdateIndividualResult;
  updateDistrictDetails?: Maybe<Scalars['Void']>;
  updateDistrictLeadership: DistrictLeadershipResult;
  updateIndividual: CreateOrUpdateIndividualResult;
  updateIndividualProgram: Program;
  /** Change a membership type Member <-> Honorary */
  updateMembershipType: UpdateMembershipTypeResult;
  /** Updates the user's password based on the token and answer to the security question. */
  updatePassword?: Maybe<Scalars['Void']>;
  /** Uploads a new photo for the given individual. */
  uploadPhoto?: Maybe<Scalars['String']>;
};


export type MutationAccountInfoByTokenArgs = {
  token: Scalars['String'];
};


export type MutationAddClubMeetingArgs = {
  input: AddClubMeetingInput;
};


export type MutationAddClubVendorArgs = {
  input: AddClubVendorInput;
};


export type MutationAddDelegationArgs = {
  input: AddDelegationInput;
};


export type MutationAddEcardDetailsArgs = {
  DedicatedTo: Scalars['String'];
  DedicationType: Scalars['Int'];
  DonorName: Scalars['String'];
  ImageFid: Scalars['Int'];
  NotificationType: Scalars['Int'];
  PersonalMsg: Scalars['String'];
};


export type MutationAddFiscalCodeArgs = {
  collectionDate: Scalars['String'];
  fiscalCode: Scalars['String'];
  netForumMemberId: Scalars['String'];
};


export type MutationAddPanArgs = {
  netForumMemberId: Scalars['String'];
  panId: Scalars['String'];
};


export type MutationAddRrnArgs = {
  collectionDate: Scalars['String'];
  netForumMemberId: Scalars['String'];
  rrnId: Scalars['String'];
};


export type MutationAddSponsorArgs = {
  clubId: Scalars['String'];
  membershipId: Scalars['String'];
  sponsorIndividualId?: Maybe<Array<Scalars['String']>>;
};


export type MutationAssignDistrictLeadershipArgs = {
  districtId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  individualId: Scalars['String'];
  riDistrictId: Scalars['String'];
  roleId: Scalars['String'];
  startDate: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationChangeSecurityQuestionArgs = {
  answer: Scalars['String'];
  currentPassword: Scalars['String'];
  question: Scalars['String'];
};


export type MutationChangeSigninEmailArgs = {
  currentPassword: Scalars['String'];
  langcode: Scalars['String'];
  newEmail: Scalars['String'];
  recaptchaToken?: Maybe<Scalars['String']>;
};


export type MutationContactUsArgs = {
  contactForm: ContactFormInput;
  email: Scalars['String'];
  langcode: Scalars['String'];
  recaptchaToken?: Maybe<Scalars['String']>;
  supportCenterEmail: Scalars['String'];
};


export type MutationCreateAccountArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  langcode: Scalars['String'];
  lastName: Scalars['String'];
  recaptchaToken?: Maybe<Scalars['String']>;
  yearOfBirth?: Maybe<Scalars['Int']>;
  youthFlag: Scalars['Boolean'];
};


export type MutationCreateConferenceArgs = {
  input: InputCreateConference;
};


export type MutationCreateFeedbackArgs = {
  input: InputCreateFeedback;
};


export type MutationCreateIndividualArgs = {
  input: CreateIndividualInput;
};


export type MutationCreateIndividualFromSessionArgs = {
  langcode: Scalars['String'];
};


export type MutationCreateIndividualProgramArgs = {
  input: CreateIndividualProgramInput;
};


export type MutationCreateLeadershipArgs = {
  clubId: Scalars['String'];
  endDate: Scalars['String'];
  individualId: Scalars['String'];
  roleId: Scalars['String'];
  startDate: Scalars['String'];
};


export type MutationCreateMembershipArgs = {
  admissionDate: Scalars['String'];
  clubId: Scalars['String'];
  individualId: Scalars['String'];
  sponsorIds?: Maybe<Array<Scalars['String']>>;
  type: Scalars['String'];
};


export type MutationDeleteClubMeetingArgs = {
  input: DeleteClubMeetingInput;
};


export type MutationDeleteConferenceArgs = {
  conferenceId: Scalars['String'];
};


export type MutationDeleteDelegationArgs = {
  input: DeleteDelegationInput;
};


export type MutationDeleteDistrictLeadershipArgs = {
  districtId: Scalars['String'];
  leadershipId: Scalars['String'];
};


export type MutationDeleteIndividualProgramArgs = {
  input: DeleteIndividualProgramInput;
};


export type MutationDeletePhotoArgs = {
  individualId: Scalars['String'];
};


export type MutationEditConferenceArgs = {
  conferenceId: Scalars['String'];
  input: InputCreateConference;
};


export type MutationEditDelegationArgs = {
  input: EditDelegationInput;
};


export type MutationEditFeedbackArgs = {
  feedbackId: Scalars['String'];
  input: InputCreateFeedback;
};


export type MutationEditMeetingArgs = {
  input: EditMeetingInput;
};


export type MutationFinalizeAccountCreationArgs = {
  accountId: Scalars['String'];
  answer: Scalars['String'];
  password: Scalars['String'];
  question: Scalars['String'];
  token: Scalars['String'];
};


export type MutationFinalizeChangeOfSigninEmailArgs = {
  token: Scalars['String'];
};


export type MutationMapByDisEmailArgs = {
  disEmail: Scalars['String'];
};


export type MutationMoveAgClubAffiliationsArgs = {
  districtId: Scalars['String'];
  movedItems: Array<MovedItem>;
  startDate: Scalars['String'];
};


export type MutationReplaceAgClubAffiliationsArgs = {
  districtId: Scalars['String'];
  individualId: Scalars['String'];
  newIndividualId: Scalars['String'];
  startDate: Scalars['String'];
};


export type MutationResendActivationEmailArgs = {
  email: Scalars['String'];
  langcode: Scalars['String'];
};


export type MutationSendLogArgs = {
  level: LogLevel;
  message: Scalars['String'];
  other?: Maybe<Scalars['String']>;
};


export type MutationSendPasswordResetMessageArgs = {
  email: Scalars['String'];
  langcode: Scalars['String'];
};


export type MutationTerminateClubVendorArgs = {
  input: TerminateClubVendorInput;
};


export type MutationTerminateLeadershipArgs = {
  clubId: Scalars['String'];
  leadershipId: Scalars['String'];
  terminationDate: Scalars['String'];
};


export type MutationTerminateMembershipArgs = {
  clubId: Scalars['String'];
  membershipId: Scalars['String'];
  terminationDate: Scalars['String'];
  terminationReasonId?: Maybe<Scalars['String']>;
};


export type MutationUpdateAgClubAffiliationsArgs = {
  districtId: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  individualId: Scalars['String'];
  newIndividualId: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
};


export type MutationUpdateClubArgs = {
  input: UpdateClubInput;
};


export type MutationUpdateContactInfoArgs = {
  input: UpdateContactInfoInput;
};


export type MutationUpdateDistrictDetailsArgs = {
  district: DistrictDetailsInput;
};


export type MutationUpdateDistrictLeadershipArgs = {
  districtId: Scalars['String'];
  isExtraData?: Maybe<Scalars['Boolean']>;
  leadershipId: Scalars['String'];
  terminationDate: Scalars['String'];
};


export type MutationUpdateIndividualArgs = {
  input: UpdateIndividualInput;
};


export type MutationUpdateIndividualProgramArgs = {
  input: UpdateIndividualProgramInput;
};


export type MutationUpdateMembershipTypeArgs = {
  admissionDate: Scalars['String'];
  clubId: Scalars['String'];
  membershipId: Scalars['String'];
  type: Scalars['String'];
};


export type MutationUpdatePasswordArgs = {
  answer: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationUploadPhotoArgs = {
  fileContentsBase64Encoded: Scalars['String'];
  fileMimeType: Scalars['String'];
  fileName: Scalars['String'];
  fileSize: Scalars['String'];
  individualId: Scalars['String'];
};

export type OfficerIndividual = {
  __typename?: 'OfficerIndividual';
  clubId?: Maybe<Scalars['String']>;
  clubLocation?: Maybe<BaseAddress>;
  clubName?: Maybe<Scalars['String']>;
  clubType?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  localizedName?: Maybe<Scalars['String']>;
  membership: OfficerMembership;
  middleName?: Maybe<Scalars['String']>;
  photoUri?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  primaryEmail?: Maybe<Scalars['String']>;
  primaryPhone?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
};

export type OfficerMembership = {
  __typename?: 'OfficerMembership';
  admissionDate?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  terminationDate?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type OktaProfileDataType = {
  __typename?: 'OktaProfileDataType';
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  registrationForm?: Maybe<Scalars['String']>;
  registrationType?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** Represents OpenInvoiceListResult. */
export type OpenInvoiceListResult = {
  __typename?: 'OpenInvoiceListResult';
  billingToUsd: Scalars['String'];
  currency: Scalars['String'];
  list: Array<InvoiceItem>;
  usdToBilling: Scalars['String'];
};

export type OptionDetail = {
  __typename?: 'OptionDetail';
  id: Scalars['String'];
  optionValue?: Maybe<Scalars['String']>;
};

export type OptionType = {
  __typename?: 'OptionType';
  id: Scalars['String'];
  optionType: Scalars['String'];
};

export enum OrgType {
  District = 'District',
  RotaractClub = 'Rotaract_Club',
  RotaryClub = 'Rotary_Club'
}

export type OrganisationDataBase = {
  __typename?: 'OrganisationDataBase';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  Key?: Maybe<Scalars['String']>;
  OrganizationId?: Maybe<Scalars['String']>;
  OrganizationName?: Maybe<Scalars['String']>;
  OrganizationType?: Maybe<Scalars['String']>;
};

export type OrganizationAddress = {
  __typename?: 'OrganizationAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
};

export enum OrganizationBase {
  Community = 'Community',
  School = 'School',
  University = 'University'
}

export type OrganizationData = {
  __typename?: 'OrganizationData';
  dominoId: Scalars['String'];
};

/** Represents Organization Details based on parameters : OrganizationType,OrganizationId. */
export type OrganizationDetailsResult = {
  __typename?: 'OrganizationDetailsResult';
  Addresses?: Maybe<Array<Maybe<AddressInfo>>>;
  CreditOrg?: Maybe<CreditOrg>;
  EmailAddresses?: Maybe<Array<Maybe<EmailAddress>>>;
  /** Organization Details */
  Id?: Maybe<Scalars['Int']>;
  Key?: Maybe<Scalars['String']>;
  Language?: Maybe<Scalars['String']>;
  LegacyTypeId?: Maybe<Scalars['String']>;
  LocalizedName?: Maybe<Scalars['String']>;
  Location?: Maybe<LocationInfo>;
  Name?: Maybe<Scalars['String']>;
  PhoneNumbers?: Maybe<Array<Maybe<PhoneNumbers>>>;
  SponsoredByOrganizations?: Maybe<Array<Maybe<Organization>>>;
  SponsoringOrganizations?: Maybe<Array<Maybe<Organization>>>;
  Type?: Maybe<Scalars['String']>;
  Websites?: Maybe<Array<Maybe<Website>>>;
};

/** Represents a PAN Number by Member ID. */
export type PanResult = {
  __typename?: 'PanResult';
  /** PAN Number. */
  PAN_c?: Maybe<Scalars['String']>;
};

export type ParticipatingDistricts = {
  __typename?: 'ParticipatingDistricts';
  id: Scalars['String'];
  riDistrictId: Scalars['Int'];
};

export type ParticipatingDistrictsInput = {
  id: Scalars['String'];
  riDistrictId: Scalars['Int'];
};

export type Payment = {
  __typename?: 'Payment';
  ExpirationMonth?: Maybe<Scalars['String']>;
  ExpirationYear?: Maybe<Scalars['String']>;
  MaskedCC?: Maybe<Scalars['String']>;
  PaymentType?: Maybe<Scalars['String']>;
};

/** Represents a Payment Profile Name by CountryISO2, CurrencyCode, TransactionTypeAlias, CustomerType, TransactionFlowName. */
export type PaymentProfileNameResult = {
  __typename?: 'PaymentProfileNameResult';
  /** Payment Profile Name. */
  PaymentProfileName?: Maybe<Scalars['String']>;
};

export type PaymentProfileResult = {
  __typename?: 'PaymentProfileResult';
  FoundationID: Scalars['String'];
  FoundationName: Scalars['String'];
  MerchantApiKey: Scalars['String'];
  MerchantId: Scalars['String'];
  WidgetId: Scalars['String'];
  paymentProfileName: Scalars['String'];
};

/** Phone input */
export type PhoneInput = {
  action?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  /** Id of the country as returned by the countries field. */
  countryId: Scalars['String'];
  /** Only allows digits, spaces, dashes, slashes, commas parentheses and dots Can ONLY start with either a numeric or open parentheses. Maximum length is 10. */
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  /** Only allows digits, spaces, dashes, slashes, commas parentheses and dots. Can ONLY start with either a numeric or open parentheses. Number/extension/type combination must not already exist for this individual. Duplicate checking must remove all non-numeric characters. Length limit is between 1 and 30. */
  number: Scalars['String'];
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  country: Scalars['String'];
  countryId: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  type?: Maybe<PhoneType>;
};

export type PhoneNumbers = {
  __typename?: 'PhoneNumbers';
  CountryCode?: Maybe<Scalars['String']>;
  IsPrimary?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  PhoneCountryName?: Maybe<Scalars['String']>;
  PhoneExtension?: Maybe<Scalars['String']>;
  PhoneNumber?: Maybe<Scalars['String']>;
  PhoneType?: Maybe<Scalars['String']>;
};

export enum PhoneType {
  Business = 'Business',
  Home = 'Home',
  Mobile = 'Mobile'
}

export type PhysicalLocation = {
  __typename?: 'PhysicalLocation';
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type PresidentialRepresentative = {
  __typename?: 'PresidentialRepresentative';
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
};

export type PrimaryPhoneInput = {
  country?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  number: Scalars['String'];
};

export type PrivacyPolicyTMessages = {
  __typename?: 'PrivacyPolicyTMessages';
  DE?: Maybe<Scalars['String']>;
  EN?: Maybe<Scalars['String']>;
  ES?: Maybe<Scalars['String']>;
  FR?: Maybe<Scalars['String']>;
  IT?: Maybe<Scalars['String']>;
  JA?: Maybe<Scalars['String']>;
  KO?: Maybe<Scalars['String']>;
  PT?: Maybe<Scalars['String']>;
};

export type Profession = {
  __typename?: 'Profession';
  employer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

/** At least one of the fields should be filled in. */
export type ProfessionInput = {
  employer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

export type Program = {
  __typename?: 'Program';
  endDate?: Maybe<Scalars['String']>;
  fields: Array<ProgramField>;
  individualProgramId: Scalars['String'];
  isVerified: Scalars['Boolean'];
  programId: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ProgramDefinition = {
  __typename?: 'ProgramDefinition';
  fieldGroups: Array<ProgramFieldGroup>;
  programId: Scalars['String'];
  title: Scalars['String'];
};

export type ProgramField = {
  __typename?: 'ProgramField';
  fieldId: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type ProgramFieldDefinition = {
  __typename?: 'ProgramFieldDefinition';
  /** Available for STRING type. */
  constraints?: Maybe<FieldConstraints>;
  fieldId: Scalars['String'];
  isRequired: Scalars['Boolean'];
  label: Scalars['String'];
  /** Available for OPTIONS type. */
  options?: Maybe<Array<FieldOption>>;
  type: FieldType;
};

export type ProgramFieldGroup = {
  __typename?: 'ProgramFieldGroup';
  defaultTranslation: Scalars['String'];
  fields: Array<ProgramFieldDefinition>;
  translationKey: Scalars['String'];
};

export type ProgramFieldInput = {
  fieldId: Scalars['String'];
  value: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** This function gets data for an Individual by its ID or riIndividualId. */
  addMemberGetIndividual: AddMemberIndividualResult;
  /** This function gets the available sponsors for the club with the given club ID and given individual ID.  */
  availableSponsors?: Maybe<MemberSearchResults>;
  basicInfo?: Maybe<BasicInfo>;
  /** Cancel subscription based on parameters : SubscriptionId,  */
  cancelSubscriptionForMember?: Maybe<CancelSubscriptionResponse>;
  /** This function gets data for the club with the given ID.  */
  clubById: Club;
  clubStatusByIds: Array<ClubStatus>;
  /** Constant values. */
  constants: Constants;
  /** Returns all the available countries. */
  countries: Array<Country>;
  currentUser?: Maybe<User>;
  /** Returns all the available display names. */
  displayNames: Array<DisplayNames>;
  /** Returns all the available districts. */
  districts?: Maybe<Array<District>>;
  expertiseAreas: Array<ExpertiseAreaItem>;
  expertiseLevels: Array<ExpertiseLevelItem>;
  getAccessLevels: Array<GetAccessLevelsResults>;
  getAllAssistantGovernors: Array<AssistantGovernor>;
  getAllDistrictAGsByDate: AssistantGovernorsByDateResults;
  getAllDistrictClubs: DistrictClubsResults;
  getAllDistrictOfficers: DistrictOfficersResults;
  getAllDistricts: Array<DistrictDis3>;
  getAllSharingPermissions: Array<SharingPermission>;
  /** Returns all ApplicationMode */
  getApplicationMode?: Maybe<Array<Maybe<ApplicationModeResult>>>;
  getAssistantGovernorsPaginate: AssistantGovernorsResults;
  /** Returns Bank Account Assignment Information */
  getBankAssignmentInfo?: Maybe<Array<Maybe<BankAssignmentResult>>>;
  /** Returns all categories */
  getCategories?: Maybe<CategoriesResult>;
  getClubBalanceReport?: Maybe<FileContentResult>;
  /** Returns club details by ClubID and ClubType */
  getClubDetails?: Maybe<Array<Maybe<ClubDetailsResult>>>;
  /** Returns invoice with success */
  getClubInvoiceReport: ClubInvoiceResult;
  /** Returns Member Details based on parameters : MemberId */
  getClubMembers?: Maybe<ClubMembersResult>;
  getCommitteeRoles: Array<CommitteeRolesResult>;
  getConference?: Maybe<GetConferenceDetailsResult>;
  getConferences: Array<GetConferencesResults>;
  /** Returns all countries */
  getCountries?: Maybe<Array<Maybe<CountryDetails>>>;
  /** Returns all country currency mapping */
  getCountryCurrencyMapping?: Maybe<Array<Maybe<CountryCurrencyMapped>>>;
  /** Returns all currencies */
  getCurrencies?: Maybe<Array<Maybe<CurrencyDetails>>>;
  getDelegationProfile: GetDelegationProfileResult;
  getDistrictClubs: DistrictClubsResults;
  getDistrictClubsStatistics: Array<DistrictClubsStatistics>;
  getDistrictDashboards: DistrictDashboardsResults;
  getDistrictDetails: DistrictDis3;
  getDistrictMembersStatistics: Array<DistrictMembersStatistics>;
  getDistrictsForLeadership: DistrictsResult;
  getDominoOrganizationId: OrganizationData;
  /** Returns ECard by ECardId */
  getEcardDetails?: Maybe<Array<Maybe<ECardDetailsResult>>>;
  /** Returns all exchange rates */
  getExchangeRates?: Maybe<Array<Maybe<ExchangeRates>>>;
  getFeedbackQuestions: Array<FeedbackQuestionsResponse>;
  /** Returns Fiscal Code by member Id */
  getFiscalCode?: Maybe<FiscalCodeResult>;
  /** Returns all funds */
  getFunds?: Maybe<Array<Maybe<Funds>>>;
  /** Returns grant details by GrantID */
  getGrantDetails?: Maybe<GrantDetailsResult>;
  /** This function gets data for an Individual by its ID or riIndividualId. */
  getIndividual: Individual;
  /** Returns Member Details based on parameters : MemberId */
  getIndividualMemberDetails?: Maybe<IndividualMemberDetailsResult>;
  /** Returns Member Details based on parameters : MemberId */
  getIndividualProfileDetails?: Maybe<IndividualProfileDetailsResult>;
  /** Returns Member Details based on parameters : MemberId */
  getIndividualSecurityGroups?: Maybe<Array<Maybe<IndividualSecurityGroupsResult>>>;
  /** This function gets Leadership History data for an Individual by its ID or riIndividualId. */
  getLeadershipHistory: Array<LeadershipResponseResult>;
  /** Returns all Legal Entities */
  getLegalEntities?: Maybe<Array<Maybe<LegalEntityResult>>>;
  /** Returns Member Details based on parameters : MemberId */
  getMemberOrganizationDetails?: Maybe<MemberOrganizationDetailsResult>;
  getMembership?: Maybe<Membership>;
  /** This function gets data for an Individual by its ID or riIndividualId. */
  getMembershipHistory: Array<MembershipHistoryBase>;
  getNonMemberDES: Array<IndividualSearchResult>;
  getOneFeedback?: Maybe<Feedback>;
  getOnlineIdEmail?: Maybe<Scalars['String']>;
  /** Returns open invoice list */
  getOpenInvoiceList?: Maybe<OpenInvoiceListResult>;
  /** Returns Organization Details based on parameters : OrganizationType,OrganizationId. */
  getOrganizationDetails?: Maybe<OrganizationDetailsResult>;
  /** Returns PAN number by member Id */
  getPan?: Maybe<PanResult>;
  /** Returns all payment profiles */
  getPaymentProfile?: Maybe<Array<Maybe<PaymentProfileResult>>>;
  /** Returns Payment Profile Name by CountryISO2, CurrencyCode, TransactionTypeAlias, CustomerType, TransactionFlowName */
  getPaymentProfileName?: Maybe<PaymentProfileNameResult>;
  getPresRepConferences: Array<GetPresRepConferencesResult>;
  getPreviousDES: Array<DistrictOfficer>;
  /** Returns privacy policy translation messages */
  getPrivacyPolicyMessages?: Maybe<PrivacyPolicyTMessages>;
  getPrograms: Array<ProgramDefinition>;
  getRecognitionInfo: RecognitionDataInfo;
  getRegionalGroupDistricts: Array<RegionalGroupDistricts>;
  /** Returns all request source */
  getRequestSource?: Maybe<Array<Maybe<RequestSource>>>;
  /** Returns RRN number by member Id */
  getRrn?: Maybe<RrnResult>;
  /** Returns States by CountryId */
  getStatesByCountry?: Maybe<Array<Maybe<StatesByCountryResult>>>;
  /** Returns all subscriptions Details based on parameters : MemberID, */
  getSubscriptionsForMember?: Maybe<Array<Maybe<Subscriptions>>>;
  getTimeZones: Array<TimeZone>;
  /** Returns Tracked Gift Details based on parameters : TrackedGiftID, DonorType, DonorID, LanguageCode */
  getTrackedGiftDetails?: Maybe<TrackedGiftDetailsResult>;
  /** Returns all TransactionType */
  getTransactionType?: Maybe<Array<Maybe<TransactionTypeResult>>>;
  /** Returns all the available languages. */
  languages: Array<LanguageExtended>;
  /** This function searches for members that match the criteria provided.  */
  memberSearchAdvanced: MemberSearchResults;
  /** Returns all the available club officer roles. */
  roles?: Maybe<Array<Role>>;
  /** This function searches for clubs that match the criteria provided.  */
  searchClub: ClubSearchResults;
  searchClubsInDistrict: DistrictClubsResults;
  searchDESByIndividualId: IndividualDesResult;
  searchDGByRY: DgResult;
  /** This function searches for individuals that match the criteria provided.  */
  searchIndividual: IndividualSearchResults;
  searchPreviousOfficers: MemberSearchResults;
  /** Runs a query on the Solr index with the criteria provided.  */
  searchSolr: SiteSearchResults;
  /** Returns all the available states */
  states: Array<State>;
  /** Returns all the available termination reasons. */
  terminationReasons: Array<TerminationReason>;
  /** Returns all the available vendors. */
  vendors?: Maybe<Array<Vendor>>;
};


export type QueryAddMemberGetIndividualArgs = {
  access?: Maybe<Scalars['String']>;
  clubId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryAvailableSponsorsArgs = {
  admissionDate?: Maybe<Scalars['String']>;
  clubId: Scalars['String'];
  individualId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryBasicInfoArgs = {
  emailId: Scalars['String'];
};


export type QueryCancelSubscriptionForMemberArgs = {
  MerchantConfig: Scalars['String'];
  SubscriptionId: Scalars['String'];
};


export type QueryClubByIdArgs = {
  clubId: Scalars['String'];
  dominoId?: Maybe<Scalars['String']>;
};


export type QueryClubStatusByIdsArgs = {
  clubIds: Array<Scalars['String']>;
};


export type QueryDisplayNamesArgs = {
  displayLanguage: Scalars['String'];
  displayType: Scalars['String'];
};


export type QueryGetAccessLevelsArgs = {
  options: Array<AccessLevelsOptions>;
};


export type QueryGetAllAssistantGovernorsArgs = {
  districtId: Scalars['String'];
};


export type QueryGetAllDistrictAGsByDateArgs = {
  date?: Maybe<Scalars['String']>;
  districtId: Scalars['String'];
};


export type QueryGetAllDistrictClubsArgs = {
  date?: Maybe<Scalars['String']>;
  districtId: Scalars['String'];
  hasAGAffiliation?: Maybe<Scalars['Boolean']>;
  isExtraData?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};


export type QueryGetAllDistrictOfficersArgs = {
  districtId: Scalars['String'];
  districtRoles?: Maybe<Array<Scalars['String']>>;
  options?: Maybe<GetAllDistrictOfficersOptions>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  riDistrictId: Scalars['Int'];
  rotaryYear: Scalars['String'];
};


export type QueryGetAllDistrictsArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};


export type QueryGetAssistantGovernorsPaginateArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  districtId: Scalars['String'];
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryGetClubBalanceReportArgs = {
  orgId: Scalars['String'];
  orgType: OrgType;
};


export type QueryGetClubDetailsArgs = {
  ClubId: Scalars['String'];
  ClubType: Scalars['String'];
};


export type QueryGetClubInvoiceReportArgs = {
  clubId: Scalars['Int'];
  clubType: Scalars['Int'];
  docType: Scalars['String'];
  isCurrentInvoice: Scalars['Boolean'];
};


export type QueryGetClubMembersArgs = {
  clubId: Scalars['String'];
  clubType: Scalars['String'];
  currentRecord: Scalars['Int'];
  recordCount: Scalars['Int'];
  status: Scalars['Boolean'];
};


export type QueryGetCommitteeRolesArgs = {
  individualId: Scalars['String'];
};


export type QueryGetConferenceArgs = {
  conferenceId: Scalars['String'];
  isDistrictPresRep?: Maybe<Scalars['Boolean']>;
};


export type QueryGetConferencesArgs = {
  districtIds: Array<Scalars['String']>;
  isExtraData?: Maybe<Scalars['Boolean']>;
  maxStartDate?: Maybe<Scalars['String']>;
  minStartDate?: Maybe<Scalars['String']>;
  presRepresentativeId?: Maybe<Scalars['String']>;
  skipFetchingRiDistrictId?: Maybe<Scalars['Boolean']>;
};


export type QueryGetDelegationProfileArgs = {
  memberId: Scalars['String'];
  page: Scalars['String'];
};


export type QueryGetDistrictClubsArgs = {
  districtId: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  rotaryYear?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
};


export type QueryGetDistrictClubsStatisticsArgs = {
  districtId: Scalars['String'];
  rotaryYear?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  types: Array<Scalars['String']>;
};


export type QueryGetDistrictDashboardsArgs = {
  clubType?: Maybe<DashboardClubType>;
  districtId: Scalars['String'];
  years: Array<Scalars['Int']>;
};


export type QueryGetDistrictDetailsArgs = {
  districtId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryGetDistrictMembersStatisticsArgs = {
  riDistrictId: Scalars['String'];
  types: Array<Scalars['String']>;
};


export type QueryGetDistrictsForLeadershipArgs = {
  role: Scalars['String'];
  rotaryYear: Scalars['Int'];
};


export type QueryGetDominoOrganizationIdArgs = {
  idType: Scalars['String'];
  value: Scalars['String'];
};


export type QueryGetEcardDetailsArgs = {
  eCardId: Scalars['String'];
};


export type QueryGetExchangeRatesArgs = {
  EffectiveDate: Scalars['String'];
  FromCurrency?: Maybe<Scalars['String']>;
  ToCurrency?: Maybe<Scalars['String']>;
};


export type QueryGetFeedbackQuestionsArgs = {
  conferenceYear: Scalars['Int'];
  feedbackType: Scalars['String'];
};


export type QueryGetFiscalCodeArgs = {
  netForumMemberId: Scalars['String'];
};


export type QueryGetGrantDetailsArgs = {
  GrantId: Scalars['String'];
};


export type QueryGetIndividualArgs = {
  delayAfterResponse?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  riIndividualId?: Maybe<Scalars['Int']>;
};


export type QueryGetIndividualMemberDetailsArgs = {
  MemberId: Scalars['String'];
};


export type QueryGetIndividualProfileDetailsArgs = {
  MemberId: Scalars['String'];
};


export type QueryGetIndividualSecurityGroupsArgs = {
  memberId: Scalars['String'];
};


export type QueryGetLeadershipHistoryArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetMemberOrganizationDetailsArgs = {
  MemberId: Scalars['String'];
};


export type QueryGetMembershipArgs = {
  clubId: Scalars['String'];
  membershipId: Scalars['String'];
};


export type QueryGetMembershipHistoryArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetNonMemberDesArgs = {
  riDistrictId: Scalars['Int'];
  rotaryYear: Scalars['String'];
};


export type QueryGetOneFeedbackArgs = {
  conferenceId: Scalars['String'];
  feedbackType: Scalars['String'];
  isDistrictPresRep?: Maybe<Scalars['Boolean']>;
};


export type QueryGetOnlineIdEmailArgs = {
  individualId: Scalars['String'];
};


export type QueryGetOpenInvoiceListArgs = {
  Id: Scalars['String'];
  Type: Scalars['String'];
};


export type QueryGetOrganizationDetailsArgs = {
  OrganizationId: Scalars['String'];
  OrganizationType: Scalars['String'];
};


export type QueryGetPanArgs = {
  netForumMemberId: Scalars['String'];
};


export type QueryGetPaymentProfileNameArgs = {
  CountryISO2: Scalars['String'];
  CurrencyCode: Scalars['String'];
  CustomerType: Scalars['String'];
  TransactionFlowName: Scalars['String'];
  TransactionTypeAlias: Scalars['String'];
};


export type QueryGetPresRepConferencesArgs = {
  individualId: Scalars['String'];
};


export type QueryGetPreviousDesArgs = {
  districtId: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  riDistrictId: Scalars['Int'];
  year: Scalars['Int'];
};


export type QueryGetRecognitionInfoArgs = {
  individualId: Scalars['String'];
};


export type QueryGetRegionalGroupDistrictsArgs = {
  regionalGroupId: Scalars['String'];
};


export type QueryGetRrnArgs = {
  netForumMemberId: Scalars['String'];
};


export type QueryGetStatesByCountryArgs = {
  CountryId: Scalars['String'];
};


export type QueryGetSubscriptionsForMemberArgs = {
  MemberID: Scalars['String'];
};


export type QueryGetTrackedGiftDetailsArgs = {
  DonorID: Scalars['String'];
  DonorType: Scalars['String'];
  LanguageCode: Scalars['String'];
  TrackedGiftID: Scalars['String'];
};


export type QueryMemberSearchAdvancedArgs = {
  allowRotaractors?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  clubIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  clubNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  clubRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  country?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  districtRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  includeDLHistory?: Maybe<Scalars['Boolean']>;
  individualId?: Maybe<Scalars['String']>;
  isDeceased?: Maybe<Scalars['Boolean']>;
  isIncludedDistrictLeadership?: Maybe<Scalars['Boolean']>;
  isIncludedDistrictMembership?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  memberClubType?: Maybe<Scalars['String']>;
  memberTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  riClubId?: Maybe<Scalars['String']>;
  rolesToExclude?: Maybe<Array<Maybe<Scalars['String']>>>;
  rotaryYear?: Maybe<Scalars['String']>;
  stateId?: Maybe<Scalars['String']>;
  yearRange?: Maybe<Array<Scalars['Int']>>;
};


export type QueryRolesArgs = {
  officerManagement?: Maybe<Scalars['String']>;
  organizationType?: Maybe<Array<Scalars['String']>>;
  rolesToInclude?: Maybe<Array<Scalars['String']>>;
  validOn?: Maybe<Scalars['String']>;
};


export type QuerySearchClubArgs = {
  advanced?: Maybe<Scalars['Boolean']>;
  clubFullNamePartial?: Maybe<Scalars['String']>;
  clubName?: Maybe<Scalars['String']>;
  clubNamePartial?: Maybe<Scalars['String']>;
  clubType?: Maybe<ClubTypeFilterValue>;
  clubTypes?: Maybe<Array<ClubTypeFilterValue>>;
  earliestMeetingTime?: Maybe<Scalars['String']>;
  isSearchByClubName?: Maybe<Scalars['Boolean']>;
  keyword?: Maybe<Scalars['String']>;
  latestMeetingTime?: Maybe<Scalars['String']>;
  meetingDay?: Maybe<Scalars['String']>;
  meetingLanguageId?: Maybe<Scalars['String']>;
  meetingLocationLatitude?: Maybe<Scalars['Float']>;
  meetingLocationLongitude?: Maybe<Scalars['Float']>;
  meetingLocationRange?: Maybe<Scalars['Float']>;
  meetingTime?: Maybe<Scalars['String']>;
  meetingType?: Maybe<MeetingTypeFilterValue>;
  orderBy?: Maybe<Scalars['String']>;
  organizationBase?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  riClubId?: Maybe<Scalars['String']>;
  riDistrictId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};


export type QuerySearchClubsInDistrictArgs = {
  clubLocation?: Maybe<Scalars['String']>;
  clubName?: Maybe<Scalars['String']>;
  clubTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  riDistrictId: Scalars['Int'];
  status?: Maybe<Scalars['String']>;
};


export type QuerySearchDesByIndividualIdArgs = {
  individualId: Scalars['String'];
};


export type QuerySearchDgByRyArgs = {
  conferenceDistrictId: Scalars['String'];
  conferenceEndDate: Scalars['String'];
  conferenceStartDate: Scalars['String'];
  relatedRY: Scalars['String'];
};


export type QuerySearchIndividualArgs = {
  advanced?: Maybe<Scalars['Boolean']>;
  clubName?: Maybe<Scalars['String']>;
  clubTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  countryId?: Maybe<Array<Maybe<Scalars['String']>>>;
  customQueryString?: Maybe<Scalars['String']>;
  customSortSyntax?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailExactMatch?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  riClubId?: Maybe<Array<Maybe<Scalars['String']>>>;
  riIndividualId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  searchNonMembers?: Maybe<Scalars['Boolean']>;
};


export type QuerySearchPreviousOfficersArgs = {
  clubRole: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  riClubId?: Maybe<Scalars['String']>;
  yearRange: Array<Scalars['Int']>;
};


export type QuerySearchSolrArgs = {
  content_type?: Maybe<Scalars['String']>;
  document_mimetype?: Maybe<Scalars['String']>;
  keywords: Scalars['String'];
  langcode?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
};


export type QueryStatesArgs = {
  countryCode?: Maybe<Scalars['String']>;
};


export type QueryTerminationReasonsArgs = {
  clubType?: Maybe<Scalars['String']>;
};

export type Question = {
  __typename?: 'Question';
  applicationFeature: Scalars['String'];
  applicationId: Scalars['String'];
  feedbackSection: FeedbackSection;
  id: Scalars['String'];
  option: Scalars['String'];
  optionDataType: Scalars['String'];
  optionDetail: OptionDetail;
  optionSeq: Scalars['Int'];
  optionType: OptionType;
};

export type QuestionResponse = {
  __typename?: 'QuestionResponse';
  questionId: Scalars['String'];
  value: Scalars['String'];
};

export enum Reason {
  Age = 'Age',
  BusinessObligations = 'BusinessObligations',
  ByClubForCause = 'ByClubForCause',
  Deceased = 'Deceased',
  FamilyObligations = 'FamilyObligations',
  Health = 'Health',
  JoiningNewClub = 'JoiningNewClub',
  JoiningRotaryClub = 'JoiningRotaryClub',
  LackOfParticipation = 'LackOfParticipation',
  NoContactUpdate = 'NoContactUpdate',
  NonAttendance = 'NonAttendance',
  NonPaymentOfClubDues = 'NonPaymentOfClubDues',
  Personal = 'Personal',
  Relocation = 'Relocation',
  SchoolGraduation = 'SchoolGraduation'
}

export type Recognition = {
  __typename?: 'Recognition';
  date: Scalars['String'];
  description: Scalars['String'];
  level: Scalars['Int'];
};

export type RecognitionClub = {
  __typename?: 'RecognitionClub';
  clubId: Scalars['String'];
  clubName: Scalars['String'];
  clubType: Scalars['String'];
  physicalLocation?: Maybe<PhysicalLocation>;
};

export type RecognitionDataInfo = {
  __typename?: 'RecognitionDataInfo';
  bronze?: Maybe<Array<Maybe<RecognitionItemInfo>>>;
  gold?: Maybe<Array<Maybe<RecognitionItemInfo>>>;
  platinum?: Maybe<Array<Maybe<RecognitionItemInfo>>>;
  silver?: Maybe<Array<Maybe<RecognitionItemInfo>>>;
};

export type RecognitionIndividual = {
  __typename?: 'RecognitionIndividual';
  club: RecognitionClub;
  firstName?: Maybe<Scalars['String']>;
  individualId: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  localizedName?: Maybe<Scalars['String']>;
  membership: RecognitionMembership;
  middleName?: Maybe<Scalars['String']>;
  photoUri?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  sponsorships: Scalars['Int'];
  suffix?: Maybe<Scalars['String']>;
};

export type RecognitionItem = {
  __typename?: 'RecognitionItem';
  recognitions: Array<Recognition>;
  type: Scalars['String'];
};

export type RecognitionItemInfo = {
  __typename?: 'RecognitionItemInfo';
  districtId: Scalars['String'];
  individuals: Array<RecognitionIndividual>;
  riDistrictId: Scalars['Int'];
};

export type RecognitionMembership = {
  __typename?: 'RecognitionMembership';
  status: Scalars['String'];
  type: Scalars['String'];
};

export type Recurrence = {
  __typename?: 'Recurrence';
  /** Date and time of each exception to the recurrence. (ISO date or date/time) */
  exdate?: Maybe<Array<Scalars['String']>>;
  /** Date and time of each occurrence that doesn't fit the pattern. (ISO date or date/time) */
  rdate?: Maybe<Array<Scalars['String']>>;
  /** Pattern by which occurrences are generated. */
  rrule?: Maybe<Array<RecurrenceRule>>;
};

export enum RecurrenceFrequency {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Minutely = 'MINUTELY',
  Monthly = 'MONTHLY',
  Secondly = 'SECONDLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type RecurrenceRule = {
  __typename?: 'RecurrenceRule';
  by?: Maybe<RecurrenceSpecifiers>;
  /** How many times to recur, including the first occurrence. */
  count?: Maybe<Scalars['Int']>;
  /** Time scale on which the recurrence rule operates. */
  freq: RecurrenceFrequency;
  /** Coefficient for freq. */
  interval?: Maybe<Scalars['Int']>;
  /** Date at which the recurrence stops recurring. (ISO date or date/time) */
  until?: Maybe<Scalars['String']>;
  /** Which day is the first day of each week. */
  wkst?: Maybe<WeekDay>;
};

export type RecurrenceSpecifiers = {
  __typename?: 'RecurrenceSpecifiers';
  /** Which day of the week at which to recur. (See: freq.) */
  day?: Maybe<Array<DaySpecifier>>;
  /** Which hour of the day at which to recur. */
  hour?: Maybe<Array<Scalars['Int']>>;
  /** Which minutes of the hour at which to recur. (0-59) */
  minute?: Maybe<Array<Scalars['Int']>>;
  /** Which seconds of the minute at which to recur. (0-60) */
  second?: Maybe<Array<Scalars['Int']>>;
};

export type RegionalGroupDistricts = {
  __typename?: 'RegionalGroupDistricts';
  DistrictId?: Maybe<Scalars['String']>;
  DistrictKey?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
  RegionalGroupId?: Maybe<Scalars['String']>;
  RegionalGroupName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
};

/** Represents request source. */
export type RequestSource = {
  __typename?: 'RequestSource';
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
  ShowPrivacyPolicy?: Maybe<Scalars['Boolean']>;
  /** request source */
  SourceGUID?: Maybe<Scalars['String']>;
  SourceName?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ResumeItem = {
  __typename?: 'ResumeItem';
  address: OrganizationAddress;
  organizationId?: Maybe<Scalars['String']>;
  organizationName: Scalars['String'];
  organizationType: Scalars['String'];
  /**
   * The individual's roles from a single organization.
   * The list is ordered descending by the biggest startDate.
   * Roles are both membership and leadership roles.
   */
  roles: Array<ResumeRole>;
};

export type ResumeRole = {
  __typename?: 'ResumeRole';
  committeeName?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  isShowOnline?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  startDate: Scalars['String'];
};

export type ResumeRoles = {
  __typename?: 'ResumeRoles';
  currentClubJoinDate?: Maybe<Scalars['String']>;
  originalJoinDate?: Maybe<Scalars['String']>;
  resumeRolesGroups?: Maybe<Array<ResumeItem>>;
  yearsOfService?: Maybe<Scalars['Float']>;
};

/** Role object, as they are returned for query */
export type Role = {
  __typename?: 'Role';
  /** The role's uuid. */
  id: Scalars['String'];
  /** If it's an officer role. */
  isOfficer?: Maybe<Scalars['Boolean']>;
  /** The name of the role. */
  name: Scalars['String'];
  /** Length of the term. */
  termLength?: Maybe<Scalars['Int']>;
  /** Terms of access after. */
  termsOfAccessAfter?: Maybe<Scalars['Int']>;
  /** Terms of access before. */
  termsOfAccessBefore?: Maybe<Scalars['Int']>;
  /** Organization type = Rotary_Club, District, etc. */
  type?: Maybe<Scalars['String']>;
};

export type RoleAccess = {
  __typename?: 'RoleAccess';
  allow?: Maybe<Array<Maybe<Scalars['String']>>>;
  deny?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RolesAuthenticationTicketDataType = {
  __typename?: 'RolesAuthenticationTicketDataType';
  clubGroupId?: Maybe<Scalars['String']>;
  clubGroupKey?: Maybe<Scalars['String']>;
  clubGroupName?: Maybe<Scalars['String']>;
  clubId?: Maybe<Scalars['String']>;
  delegation?: Maybe<Scalars['String']>;
  delegationEndDate?: Maybe<Scalars['String']>;
  delegationStartDate?: Maybe<Scalars['String']>;
  delegatorIndividualKey?: Maybe<Scalars['String']>;
  delegatorMemberId?: Maybe<Scalars['String']>;
  delegatorSortName?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  districtKey?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  individualKey?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  positionType?: Maybe<Scalars['String']>;
  preferredLanguage?: Maybe<Scalars['String']>;
  regionalGroupId?: Maybe<Scalars['String']>;
  regionalGroupKey?: Maybe<Scalars['String']>;
  regionalGroupName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  roleKey?: Maybe<Scalars['String']>;
  rotaractClubId?: Maybe<Scalars['String']>;
  rotaractClubKey?: Maybe<Scalars['String']>;
  rotaryClubKey?: Maybe<Scalars['String']>;
  termAfterDuration?: Maybe<Scalars['Int']>;
  termBeforeDuration?: Maybe<Scalars['Int']>;
  termEndDate?: Maybe<Scalars['String']>;
  termStartDate?: Maybe<Scalars['String']>;
  userSortName?: Maybe<Scalars['String']>;
};

/** Represents a RRN Number by Member ID. */
export type RrnResult = {
  __typename?: 'RrnResult';
  /** RRN Number. */
  RRN_c?: Maybe<Scalars['String']>;
};

/** An OKTA security question. */
export type SecurityQuestion = {
  __typename?: 'SecurityQuestion';
  id: Scalars['String'];
  text: Scalars['String'];
};

export type SharingPermission = {
  __typename?: 'SharingPermission';
  id: Scalars['String'];
  label: Scalars['String'];
};

export type SharingPermissionResult = {
  __typename?: 'SharingPermissionResult';
  address: Scalars['String'];
  email: Scalars['String'];
  memberId: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type SharingPermissionsExtended = {
  __typename?: 'SharingPermissionsExtended';
  aboutMe: SharingPermission;
  address: SharingPermission;
  dateOfBirth: SharingPermission;
  email: SharingPermission;
  expertise: SharingPermission;
  fax: SharingPermission;
  gender: SharingPermission;
  language: SharingPermission;
  name: SharingPermission;
  phone: SharingPermission;
  photo: SharingPermission;
  profession: SharingPermission;
  program: SharingPermission;
  recognition: SharingPermission;
  sponsors: SharingPermission;
};

/** Each field takes the ID of a sharing permission to set. */
export type SharingPermissionsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expertise?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  recognition?: Maybe<Scalars['String']>;
};

export type SharingPermissionsRegular = {
  __typename?: 'SharingPermissionsRegular';
  aboutMe: Scalars['Boolean'];
  address: Scalars['Boolean'];
  dateOfBirth: Scalars['Boolean'];
  email: Scalars['Boolean'];
  expertise: Scalars['Boolean'];
  fax: Scalars['Boolean'];
  gender: Scalars['Boolean'];
  language: Scalars['Boolean'];
  name: Scalars['Boolean'];
  phone: Scalars['Boolean'];
  photo: Scalars['Boolean'];
  profession: Scalars['Boolean'];
};

export type SharingPermissionsResult = {
  __typename?: 'SharingPermissionsResult';
  address: Scalars['Boolean'];
  email: Scalars['Boolean'];
  name: Scalars['Boolean'];
  phone: Scalars['Boolean'];
  photo: Scalars['Boolean'];
};

export type ShortClubInfo = {
  __typename?: 'ShortClubInfo';
  clubId: Scalars['String'];
  clubName: Scalars['String'];
  clubType: Scalars['String'];
  meetings?: Maybe<Array<Maybe<Meeting>>>;
};

/** Site search result object */
export type SiteSearchResults = {
  __typename?: 'SiteSearchResults';
  facet_counts: SolrFacets;
  highlighting?: Maybe<Array<SolrHighlighting>>;
  response: SolrResponse;
  responseHeader: SolrResponseHeader;
  spellcheckSuggestion?: Maybe<Scalars['String']>;
};

export type SolrFacetFieldOption = {
  __typename?: 'SolrFacetFieldOption';
  count: Scalars['String'];
  value: Scalars['String'];
};

export type SolrFacetFields = {
  __typename?: 'SolrFacetFields';
  content_type: Array<Maybe<SolrFacetFieldOption>>;
  document_mimetype?: Maybe<Array<Maybe<SolrFacetFieldOption>>>;
  langcode?: Maybe<Array<Maybe<SolrFacetFieldOption>>>;
  topic: Array<Maybe<SolrFacetFieldOption>>;
};

/** The facet data returned from Solr. */
export type SolrFacets = {
  __typename?: 'SolrFacets';
  facet_fields: SolrFacetFields;
};

/** The highlighting data returned from Solr. */
export type SolrHighlighting = {
  __typename?: 'SolrHighlighting';
  content_fulltext: Array<Maybe<Scalars['String']>>;
  id: Scalars['String'];
};

/** The main Solr response object, contains the results in docs property. */
export type SolrResponse = {
  __typename?: 'SolrResponse';
  docs: Array<SolrResultItem>;
  numFound: Scalars['Int'];
  start: Scalars['Int'];
};

/** Solr response header object, metadata on the response. */
export type SolrResponseHeader = {
  __typename?: 'SolrResponseHeader';
  QTime: Scalars['Int'];
  status: Scalars['Int'];
  zkConnected: Scalars['Boolean'];
};

/** The Solr index item. */
export type SolrResultItem = {
  __typename?: 'SolrResultItem';
  _version_: Scalars['String'];
  bundle?: Maybe<Scalars['String']>;
  content_fulltext: Array<Maybe<Scalars['String']>>;
  content_type?: Maybe<Scalars['String']>;
  date_created: Scalars['String'];
  date_modified: Scalars['String'];
  document_file_size?: Maybe<Scalars['Int']>;
  document_mimetype?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  item_id?: Maybe<Scalars['String']>;
  langcode?: Maybe<Scalars['String']>;
  role?: Maybe<Array<Maybe<Scalars['String']>>>;
  site: Scalars['String'];
  source_id: Scalars['String'];
  source_path?: Maybe<Scalars['String']>;
  sticky?: Maybe<Scalars['Boolean']>;
  title_fulltext: Scalars['String'];
  topic?: Maybe<Array<Maybe<Scalars['String']>>>;
  weight?: Maybe<Scalars['Int']>;
};

export type SponsorshipItem = {
  __typename?: 'SponsorshipItem';
  club: FormattedClub;
  membershipList: Array<FormattedMembership>;
};

export type SponsorshipResult = {
  __typename?: 'SponsorshipResult';
  sponsoredByMe: Array<SponsorshipItem>;
  sponsoredBySomeone: Array<SponsorshipItem>;
};

/** Represents a state by country code. */
export type State = {
  __typename?: 'State';
  /** The id of the country the states belongs to. */
  countryId: Scalars['String'];
  /** The state's id. */
  id: Scalars['String'];
  /** The name of the State. */
  name: Scalars['String'];
};

/** Represents StatesByCountryResult. */
export type StatesByCountryResult = {
  __typename?: 'StatesByCountryResult';
  CountryKey?: Maybe<Scalars['String']>;
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['Boolean']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
  StateAbbreviation?: Maybe<Scalars['String']>;
  StateKey?: Maybe<Scalars['String']>;
};

export type SubSponser = {
  __typename?: 'SubSponser';
  id?: Maybe<Scalars['String']>;
};

/** Represents subscriptions. */
export type Subscriptions = {
  __typename?: 'Subscriptions';
  Address?: Maybe<AddressDetails>;
  Amount?: Maybe<Scalars['String']>;
  Currency?: Maybe<Scalars['String']>;
  EmailAddress?: Maybe<Scalars['String']>;
  Frequency?: Maybe<Scalars['String']>;
  FundName?: Maybe<Scalars['String']>;
  FundType?: Maybe<Scalars['String']>;
  Individual?: Maybe<IndividualDetails>;
  MerchantConfig?: Maybe<Scalars['String']>;
  NextChargeTimestamp?: Maybe<Scalars['String']>;
  NextCharged?: Maybe<Scalars['String']>;
  Payment?: Maybe<Payment>;
  PhoneNumber?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  SubFundType?: Maybe<Scalars['String']>;
  SubscriptionCreatedBy?: Maybe<Scalars['String']>;
  SubscriptionCreationDate?: Maybe<Scalars['String']>;
  SubscriptionCreationSource?: Maybe<Scalars['String']>;
  /** subscriptions */
  SubscriptionId?: Maybe<Scalars['String']>;
  SubscriptionLastModificationDate?: Maybe<Scalars['String']>;
  TranslatedFrequency?: Maybe<Scalars['String']>;
  WidgetID?: Maybe<Scalars['String']>;
};

export type SuccessAssignmentsResult = {
  __typename?: 'SuccessAssignmentsResult';
  body?: Maybe<SuccessBody>;
  id: Scalars['Int'];
  statusCode: Scalars['Int'];
};

export type SuccessBody = {
  __typename?: 'SuccessBody';
  endDate: Scalars['String'];
  id: Scalars['String'];
  message: Scalars['String'];
  startDate: Scalars['String'];
};

/** The term a role is filled by an individual. */
export type Term = {
  __typename?: 'Term';
  /** Format: YYYY-MM-DD */
  from: Scalars['String'];
  /** Format: YYYY-MM-DD */
  to?: Maybe<Scalars['String']>;
};

/** Input for the terminateClubVendors mutation */
export type TerminateClubVendorInput = {
  clubKey: Scalars['String'];
  requestorKey: Scalars['String'];
  vendorKey: Scalars['String'];
};

export type TerminateClubVendorResult = {
  __typename?: 'TerminateClubVendorResult';
  /** Terminated vendor id? */
  vendorId?: Maybe<Scalars['String']>;
};

/** Termination Reason object, as they are returned for query */
export type TerminationReason = {
  __typename?: 'TerminationReason';
  /** Club type */
  clubType: Scalars['String'];
  /** The termination reason's uuid. */
  id: Scalars['String'];
  isNotAllowed?: Maybe<Scalars['Boolean']>;
  /** The termination reason descriptive text. */
  reason: Reason;
};

export type TimeZone = {
  __typename?: 'TimeZone';
  id: Scalars['String'];
  offset: Scalars['String'];
  timeZoneName: Scalars['String'];
  value: Scalars['String'];
};

/** Represents Tracked Gift Details based on parameters : TrackedGiftID, DonorType, DonorID, LanguageCode. */
export type TrackedGiftDetailsResult = {
  __typename?: 'TrackedGiftDetailsResult';
  ALIAS_CODE?: Maybe<Scalars['String']>;
  ALIAS_DESCR?: Maybe<Scalars['String']>;
  ALIAS_DESCR_LANG?: Maybe<Scalars['String']>;
  DESIGNATION_DESCR?: Maybe<Scalars['String']>;
  ENDOWMENT_TYPE?: Maybe<Scalars['String']>;
  FUND?: Maybe<Scalars['String']>;
  GIFT_NAME?: Maybe<Scalars['String']>;
  GIFT_TYPE?: Maybe<Scalars['String']>;
  /** Tracked Gift Details */
  STATUS?: Maybe<Scalars['String']>;
  SUB_FUND?: Maybe<Scalars['String']>;
  TRACKED_GIFT_ID?: Maybe<Scalars['String']>;
};

/** Represents TransactionTypeResult. */
export type TransactionTypeResult = {
  __typename?: 'TransactionTypeResult';
  Alias?: Maybe<Scalars['String']>;
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  FundId?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['String']>;
  IsRecurring?: Maybe<Scalars['String']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
  SubFundId?: Maybe<Scalars['String']>;
  TransactionTypeKey?: Maybe<Scalars['String']>;
  TransactionTypeTranslations?: Maybe<Array<Maybe<TransactionTypeTranslations>>>;
  TypeName?: Maybe<Scalars['String']>;
};

export type TransactionTypeTranslations = {
  __typename?: 'TransactionTypeTranslations';
  CreatedByUser?: Maybe<Scalars['String']>;
  DateCreated?: Maybe<Scalars['String']>;
  DateModified?: Maybe<Scalars['String']>;
  IsDeleted?: Maybe<Scalars['String']>;
  LanguageCode?: Maybe<Scalars['String']>;
  ModifiedByUser?: Maybe<Scalars['String']>;
  TransactionTypeKey?: Maybe<Scalars['String']>;
  TransactionTypeTranslationKey?: Maybe<Scalars['String']>;
  TranslatedLearnMore?: Maybe<Scalars['String']>;
  TranslatedName?: Maybe<Scalars['String']>;
};

/** Input for the updateClub mutation */
export type UpdateClubInput = {
  /** Club chartered date. */
  charteredDate?: Maybe<Scalars['String']>;
  /** ID of the club to update. */
  clubId: Scalars['String'];
  /** Club type. */
  clubType?: Maybe<Scalars['String']>;
  /** Club district. */
  district?: Maybe<Scalars['String']>;
  /** Domino ID */
  dominoId?: Maybe<Scalars['String']>;
  /** Club primary email */
  email?: Maybe<DominoEmailInput>;
  /** Club mailing address. */
  mailingAddress?: Maybe<BaseAddressInput>;
  /** Club meetings. */
  meetings?: Maybe<Array<MeetingInput>>;
  /** Club name. */
  name?: Maybe<Scalars['String']>;
  /** Club language */
  officialLanguage?: Maybe<BasicLanguageInput>;
  /** Club's physical location */
  physicalLocation?: Maybe<BaseAddressInput>;
  /** Club contact email */
  primaryEmail?: Maybe<Scalars['String']>;
  /** Club contact fax */
  primaryFax?: Maybe<FaxInput>;
  /** Club contact phone number */
  primaryPhone?: Maybe<PhoneInput>;
  /** Club contact web site */
  primaryWebsite?: Maybe<Scalars['String']>;
  /** Club's riClubId */
  riClubId?: Maybe<Scalars['String']>;
  /** Club type. */
  type?: Maybe<Scalars['String']>;
  /** Club website */
  website?: Maybe<WebsiteInput>;
};

/** Result of the updateClub mutation. */
export type UpdateClubResult = {
  __typename?: 'UpdateClubResult';
  clubId: Scalars['String'];
};

export type UpdateContactInfoInput = {
  contactInfo: DominoContactInfo;
};

export type UpdateIndividualInput = {
  aboutMe?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  /** First name (Given name). Max length is 30 chars. Cannot start with a space, dash or dot. Mostly latin characters, DIS regex: `/^[\\ -\\ɏ]*$/`. */
  firstName?: Maybe<Scalars['String']>;
  /**
   * Gender. One of Male, Female, Not Given, Not to Identify, Self Identify. If
   * not provided, set to Not Given.
   */
  gender?: Maybe<Gender>;
  /** Gender description. Provided when Gender is Self Identify. */
  genderDescription?: Maybe<Scalars['String']>;
  /** ID of the individual to update. */
  individualId: Scalars['String'];
  /** Last name (Family name). Max length is 30 chars. Cannot start with a space, dash or dot. Mostly latin characters, DIS regex: `/^[\\ -\\ɏ]*$/`. */
  lastName?: Maybe<Scalars['String']>;
  /** Localized name (Full name). Max length is 200 chars. Supports uncode. */
  localizedName?: Maybe<Scalars['String']>;
  /** Middle name. Max length is 30 chars. Cannot start with a space, dash or dot. Mostly latin characters, DIS regex: `/^[\\ -\\ɏ]*$/`. */
  middleName?: Maybe<Scalars['String']>;
  otherAddresses?: Maybe<Array<AddressInput>>;
  otherEmails?: Maybe<Array<EmailInput>>;
  otherExpertise?: Maybe<Array<ExpertiseInput>>;
  otherLanguages?: Maybe<Array<Scalars['String']>>;
  otherPhones?: Maybe<Array<IndividualPhoneInput>>;
  otherProfessions?: Maybe<Array<ProfessionInput>>;
  /** Id of the preferred language */
  preferredLanguage?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  /** A physical location. */
  primaryAddress?: Maybe<AddressInput>;
  /** Email address. */
  primaryEmail?: Maybe<EmailInput>;
  /** Areas of expertise. */
  primaryExpertise?: Maybe<ExpertiseInput>;
  /** Id of the primary language as returned by the languages field. */
  primaryLanguage?: Maybe<Scalars['String']>;
  /** A phone number. */
  primaryPhone?: Maybe<IndividualPhoneInput>;
  /** Professional experience. */
  primaryProfession?: Maybe<ProfessionInput>;
  /** Allow share sponsorship or not */
  recognitionOptOutFlag?: Maybe<Scalars['Boolean']>;
  sharingPermissionsExtended?: Maybe<SharingPermissionsInput>;
  suffix?: Maybe<Scalars['String']>;
  useInternalKey?: Maybe<Scalars['Boolean']>;
  /** Format YYYY. Must be between this year and 100 years ago. */
  yearOfBirth?: Maybe<Scalars['Int']>;
};

export type UpdateIndividualProgramInput = {
  fields?: Maybe<Array<ProgramFieldInput>>;
  individualId: Scalars['String'];
  individualProgramId: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  /** The list of security questions for the user to choose from. */
  availableSecurityQuestions: Array<SecurityQuestion>;
  /** The current security question. */
  currentSecurityQuestion?: Maybe<Scalars['String']>;
  /** Impersonation session expiration */
  impersonationExpiration?: Maybe<Scalars['String']>;
  /** The current Impersonator Id */
  impersonatorId?: Maybe<Scalars['String']>;
  individualId?: Maybe<Scalars['String']>;
  login: Scalars['String'];
  nfKey?: Maybe<Scalars['String']>;
  oktaId: Scalars['String'];
  /** User profile */
  profile?: Maybe<UserProfile>;
  riIndividualId?: Maybe<Scalars['String']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  /** User firstname */
  firstName?: Maybe<Scalars['String']>;
  /** User lastname */
  lastName?: Maybe<Scalars['String']>;
};

/** Represents a vendor */
export type Vendor = {
  __typename?: 'Vendor';
  /** Vendor Key */
  Key: Scalars['String'];
  /** Vendor Name */
  Name: Scalars['String'];
};

export type VendorRequestor = {
  __typename?: 'VendorRequestor';
  add?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  edit?: Maybe<Scalars['Boolean']>;
  get?: Maybe<Scalars['Boolean']>;
  isPreferred?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  lastUpdated?: Maybe<Scalars['String']>;
  organizationKey: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  organizationType: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
};

export type Website = {
  __typename?: 'Website';
  IsPrimary?: Maybe<Scalars['Boolean']>;
  Key?: Maybe<Scalars['String']>;
  LastUpdated?: Maybe<Scalars['String']>;
  WebsiteAddress?: Maybe<Scalars['String']>;
  WebsiteType?: Maybe<Scalars['String']>;
};

export type WebsiteInput = {
  action?: Maybe<Scalars['String']>;
  /** Website identifier */
  id?: Maybe<Scalars['String']>;
  /** Other club websites */
  otherWebsites?: Maybe<Scalars['String']>;
  /** club primary website */
  primaryWebsite?: Maybe<Scalars['String']>;
};

export type WebsiteType = {
  __typename?: 'WebsiteType';
  /** Website identifier */
  id: Scalars['String'];
  /** Other club websites */
  otherWebsites?: Maybe<Scalars['String']>;
  /** club primary website */
  primaryWebsite: Scalars['String'];
};

export enum WeekDay {
  Fr = 'FR',
  Mo = 'MO',
  Sa = 'SA',
  Su = 'SU',
  Th = 'TH',
  Tu = 'TU',
  We = 'WE'
}

/** Club Address input */
export enum WeekdayFull {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

/** Represents a validation error returned by Oracle. */
export type AddFiscalCodeError = {
  __typename?: 'addFiscalCodeError';
  /** The type of the error. */
  body: Scalars['String'];
  /** The field that failed validation. */
  code: Scalars['String'];
  /** The error message. */
  statusText: Scalars['String'];
};

/** Represents a validation error returned by Oracle. */
export type AddPanError = {
  __typename?: 'addPanError';
  /** The type of the error. */
  body: Scalars['String'];
  /** The field that failed validation. */
  code: Scalars['String'];
  /** The error message. */
  statusText: Scalars['String'];
};

/** Represents a validation error returned by Oracle. */
export type AddRrnError = {
  __typename?: 'addRrnError';
  /** The type of the error. */
  body: Scalars['String'];
  /** The field that failed validation. */
  code: Scalars['String'];
  /** The error message. */
  statusText: Scalars['String'];
};

/** Represents a validation error returned by DIS. */
export type AddSponsorError = {
  __typename?: 'addSponsorError';
  /** The type of the error. */
  code: Scalars['String'];
  /** The error message. */
  description: Scalars['String'];
  /** The field that failed validation. */
  source: Scalars['String'];
};

/** Represents a validation error returned by DIS. */
export type CreateLeadershipError = {
  __typename?: 'createLeadershipError';
  /** The type of the error. */
  code: Scalars['String'];
  /** The error message. */
  description: Scalars['String'];
  /** The field that failed validation. */
  source: Scalars['String'];
};

/** Result of the createIndividual mutation. */
export type CreateLeadershipResult = {
  __typename?: 'createLeadershipResult';
  leadership?: Maybe<LeadershipResult>;
};

/** Represents a validation error returned by DIS. */
export type CreateMembershipError = {
  __typename?: 'createMembershipError';
  /** The type of the error. */
  code: Scalars['String'];
  /** The error message. */
  description: Scalars['String'];
  /** The field that failed validation. */
  source: Scalars['String'];
};

/** Result of the createIndividual mutation. */
export type CreateMembershipResult = {
  __typename?: 'createMembershipResult';
  membership?: Maybe<MembershipResult>;
};

/** Result of the createECard mutation. */
export type EcardAddResult = {
  __typename?: 'ecardAddResult';
  ecardId?: Maybe<Scalars['String']>;
};

/** Result of the addFiscalCode Mutation. */
export type FiscalCodeInfoResult = {
  __typename?: 'fiscalCodeInfoResult';
  errorMsg?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Leadership Object */
export type LeadershipResult = {
  __typename?: 'leadershipResult';
  /** The date on which this leadership will be valid until */
  endDate?: Maybe<Scalars['String']>;
  /** Leadership's ID */
  id: Scalars['String'];
  /** Individual's ID that this leadership refers to */
  individualId: Scalars['String'];
  /** Shows if leadership is delegated */
  isDelegated?: Maybe<Scalars['Boolean']>;
  /** Leadership Role description */
  role: Scalars['String'];
  /** Leadership Role ID' */
  roleId: Scalars['String'];
  /** The date on which this leadership will be valid from */
  startDate: Scalars['String'];
};

/** Membership Object */
export type MembershipResult = {
  __typename?: 'membershipResult';
  /** The date on which this membership will be valid from */
  admissionDate: Scalars['String'];
  /** The date on which this membership will be valid from */
  id: Scalars['String'];
  /** Individual's ID that this membership refers to */
  individualId: Scalars['String'];
  /** If terminated, contains the date (should be null for newly created Membership) */
  terminationDate?: Maybe<Scalars['String']>;
  /** If terminated, displays termination reason's text(should be null for newly created Membership) */
  terminationReason?: Maybe<Scalars['String']>;
  /** If terminated, the termination reason's ID(should be null for newly created Membership) */
  terminationReasonId?: Maybe<Scalars['String']>;
  /** Membership's type. Can be either 'Member' or 'Honorary' */
  type: Scalars['String'];
};

/** Result of the createIndividual mutation. */
export type PanInfoResult = {
  __typename?: 'panInfoResult';
  pan?: Maybe<Scalars['String']>;
};

/** Result of the createIndividual mutation. */
export type RrnInfoResult = {
  __typename?: 'rrnInfoResult';
  rrn?: Maybe<Scalars['String']>;
};

/** Represents a validation error returned by DIS. */
export type TerminateLeadershipError = {
  __typename?: 'terminateLeadershipError';
  /** The type of the error. */
  code: Scalars['String'];
  /** The error message. */
  description: Scalars['String'];
  /** The field that failed validation. */
  source: Scalars['String'];
};

/** Represents a validation error returned by DIS. */
export type TerminateMembershipError = {
  __typename?: 'terminateMembershipError';
  /** The type of the error. */
  code: Scalars['String'];
  /** The error message. */
  description: Scalars['String'];
  /** The field that failed validation. */
  source: Scalars['String'];
};

/** Result of the createIndividual mutation. */
export type UpdateMembershipTypeResult = {
  __typename?: 'updateMembershipTypeResult';
  /** If operation is successful, created after successful operation */
  membership?: Maybe<MembershipResult>;
};
