export enum ErrorCode {
  // We really have no idea what has happened.
  Unexpected = 'Unexpected',
  // A request to one of the external services (DIS, Okta, etc.) resulted in
  // error.
  ExternalService = 'ExternalService',
  // There was a validation error. User might need to change the input data and
  // try again. User errors can be found in extensions.validationErrorMessages.
  Validation = 'Validation',
  // Just like a Validation error, but user have no chance to fix it. There
  // might be user messages in extensions.errorMessages.
  General = 'General',
  // Operation needs an authenticated user.
  UserNotAuthenticated = 'UserNotAuthenticated',
  // Operation needs a mapped user.
  UserNotMapped = 'UserNotMapped',
  // Impersonation failed.
  ImpersonationError = 'ImpersonationError',
  // Feature is disabled
  FeatureDisabled = 'FeatureDisabled',
  // Authcontroller does not have user data
  AuthcontrollerDataNotFound = 'AuthcontrollerDataNotFound',
}
